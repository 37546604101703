import * as yup from 'yup';

const validationSchema = yup.object().shape({
  company: yup.string().required(' '),
  first_name: yup.string().required(' '),
  last_name: yup.string().required(' '),
  email: yup
    .string()
    .email('signup:email_invalid')
    .required('signup:email_invalid'),

  phone: yup.string(),
});

export default validationSchema;
