import {
  PayloadAction,
    createAsyncThunk,
    createSlice,
  } from "@reduxjs/toolkit";
  import http from "services/http/http";
  import ApiException, { ApiSearchQueryParams, ApiSearchResult } from "types/api";
import { CampaignCreateBody, CampaignListItem } from "interfaces/campaign";
import { PaginatedResponse } from "interfaces/api";
  
  const BASE = "campaigns";
  
  // Initial State
  interface CampaignsState {
    loading: boolean;
    campaigns: CampaignListItem[];
    total: number;
  }
  
  const initialState: CampaignsState = {
    loading: false,
    campaigns: [],
    total: 0,
  };
  
  // Slice
  
  const campaignsSlice = createSlice({
    name: BASE,
    initialState,
    reducers: {
      setCampaigns(state, action: PayloadAction<CampaignListItem[]>){
        return {
          ...state,
          campaigns: action.payload
        }
      },
      setCampaignLoading(state, action: PayloadAction<boolean>){
        return {
          ...state,
          loading: action.payload
        }
      },
    },
    extraReducers: (builder) => {
      builder.addCase(fetchCampaigns.pending, (state, action) => {
        state.loading = true;
      });
      builder.addCase(fetchCampaigns.fulfilled, (state, action) => {
        state.campaigns = action.payload.results;
        state.total = action.payload.count;
        state.loading = false;
      });
      builder.addCase(fetchCampaigns.rejected, (state, action) => {
        state.loading = false;
      });
    },
  });
  
  export const fetchCampaigns = createAsyncThunk(
    `${BASE}/fetchCampaigns`,
    async (
      {
        searchParams,
        filterObj = {},
      }: { searchParams?: ApiSearchQueryParams; filterObj?: any },
      { rejectWithValue }
    ) => {
      return await http
        .request<PaginatedResponse<CampaignListItem>>({
          key: "getCampaigns",
          queryParams: searchParams,
        })
        .catch((err: ApiException) => rejectWithValue(err));
    }
  );

  export const createCampaign = createAsyncThunk(
    `${BASE}/createCampaign`,
    async (
      data: CampaignCreateBody,
      { rejectWithValue }
    ) => {
      return http
        .request<CampaignListItem>({
          key: "createCampaign",
          data,
        })
        .then((res) => {
          return res;
        })
        .catch((err: ApiException) => rejectWithValue(err));
    }
  );
  
  export default campaignsSlice;
  