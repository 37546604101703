import { Card } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import MDBox from "components/MDBox"
import { PAGE_HEADER_HEIGHT, PAGE_HEADER_HEIGHT_MOBILE, PageHeaderStyle } from "layouts/Main/styles"
import { ReactNode } from "react"
import { useAppSelector } from "store/hooks"
import { getIsMobile } from "store/slices/general"

const PageHeaderContainer = ({ children, headerSize }: { children: ReactNode, headerSize?: number }) => {
    const isMobile = useAppSelector(getIsMobile)
    return <MDBox sx={{
        height: pxToRem(
            isMobile
                ? PAGE_HEADER_HEIGHT_MOBILE
                : headerSize ?? PAGE_HEADER_HEIGHT
        ),
    }}>
        {children}
    </MDBox>
}

export default PageHeaderContainer