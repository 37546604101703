export const pdfSources = {
    manual: {
        pageTitle: 'navigation:manuals',
        link: '/user-manual.pdf',
    },
    capabilities: {
        pageTitle: 'navigation:capabilities',
        link: '/capabilities.pdf',
    },
    guide: {
        pageTitle: 'navigation:guide',
        link: '/interface-guide.pdf',
    }
};

export type ContextType = keyof typeof pdfSources;