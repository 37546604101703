import { t } from "i18next";
import { SelectItem } from "types/select";

export const toSelectItem = (
  value: string | number,
  label: string
): SelectItem => {
  return { value: value.toString(), label };
};

const allText = t("general:all");
export const ALL_OPTION: SelectItem = { value: 'all_option_value', label: allText };
