import { Auth, Hub } from "aws-amplify";
import dayjs from "dayjs";
import { AuditLogStatus } from "enums/audit-log-status.enum";
import { t } from "i18next";
import { PASSWORD_LAST_UPDATE_ATTRIBUTE } from "layouts/authentication/auth.consts";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { fetchLoggedUser } from "store/slices/users-data";
import { ResetPageState, ResetPageStateSource } from "types/auth";
import { UserData } from "types/user-data";
import { logout } from "utils/auth.utils";
import { isBackofficeUser } from "utils/env.utils";
import { addLoginLog } from "utils/server-log.utils";
import { toastifyError } from "utils/toastify-message";

const INACTIVITY_TIMEOUT = 30 * 60 * 1000; // 30 minutes

const useAppAuth = () => {
  const [isLoggedIn, setIsLogged] = useState<boolean>(undefined);
  const isLoginAllowed = useAppSelector(state => state.auth.isLoginAllowed);
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // logout user after inactivity of X minutes
    let inactivityTimer: NodeJS.Timeout | undefined;

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(async () => {
        logout();
      }, INACTIVITY_TIMEOUT);
    };

    // Reset the inactivity timer on user activity
    window.addEventListener("mousemove", resetInactivityTimer);
    window.addEventListener("keydown", resetInactivityTimer);

    resetInactivityTimer();

    return () => {
      clearTimeout(inactivityTimer);
      window.removeEventListener("mousemove", resetInactivityTimer);
      window.removeEventListener("keydown", resetInactivityTimer);
    };
  }, []);

  useEffect(() => {
    getSession();
    Hub.listen("auth", listener);
  }, [isLoginAllowed]);

  // useEffect(() => {
  //   if (isLoggedIn === false && isBackofficeUser) {
  //     Auth.federatedSignIn({ customProvider: "AzureAD" });
  //   }
  // }, [isLoggedIn]);

  const requireUpdatePassword = async (userData: UserData) => {
    const threeMonthsAgo = dayjs().subtract(3, "months");
    const lastPasswordUpdate = userData.passwordHistories?.[0].createdAt;
    if (!lastPasswordUpdate) {
      throw new Error("user missing info required for validating password");
    }
    if (dayjs(lastPasswordUpdate).isBefore(threeMonthsAgo)) {
      /* --- for now can't call confirm outside Main.tsx
        await confirm({
          header: t("auth:requiredChangePassword"),
          okLabel: t("common:continue"),
          type: ConfirmDialogTypes.WARNING,
        }); */
      toastifyError(t("auth:requiredChangePassword"), { autoClose: false });
      navigate("/resetPassword", {
        state: {
          source: ResetPageStateSource.REQUIRED_CHANGE_PASSWORD,
        } as ResetPageState,
      });
      return true;
    } else {
      return false;
    }
  };

  const getSession = async () => {
    try {
      const currentSession = await Auth.currentSession();
      const accessToken = currentSession?.getAccessToken()?.getJwtToken();
      const idToken = currentSession?.getIdToken()?.getJwtToken();
      if (accessToken && idToken) {
        if (isBackofficeUser) {
          setIsLogged(true);
        } else {
            const userData = await appDispatch(
              fetchLoggedUser()
            ).unwrap();
            setIsLogged(!!userData.data.email);
            if (!userData.data.company) {
              return navigate("/update-details", { replace: true });
            }
        }
      } else {
        setIsLogged(false);
      }
    } catch (e: any) {
      console.log({ e });
      setIsLogged(false);
    } finally {
    }
  };

  const listener = (data: any) => {
    switch (data.payload.event) {
      case "signIn":
        // note: for portal user the log is in Login.tsx
        // isBackofficeUser &&
        //   addLoginLog(data?.payload?.data?.username, AuditLogStatus.Success);
        getSession();
        break;
      case "signOut":
        setIsLogged(false);
        break;
    }
  };

  return { isLoggedIn: isLoggedIn && isLoginAllowed };
};

export default useAppAuth;
