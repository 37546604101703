export interface UserData {
  id: number;
  clientId: number;
  clientSapNumber: number;
  fullName: string;
  role: UserDataRole;
  email: string;
  phone: string;
  createdAt: Date;
  isActive: boolean;
  lastUpdatedStatus: Date;
  permissionsToPortal: boolean;
  acceptedTermsAndConditions: boolean;
  passwordHistories?: { createdAt: string }[];
}

export enum UserDataRole {
  ADMIN = "ADMIN",
  PORTAL = "PORTAL",
}

export interface UserDataFilters {
  clientId: number;
  userId: number;
  userEmail: string;
  onlyActive: boolean;
}
