// @mui material components
import { Radio } from "@mui/material";
import { Theme, styled } from "@mui/material/styles";
import { globalColors } from "assets/colors-global";

export default styled(Radio)(
  ({ theme }: { theme?: Theme }) => {
    return {
      '&:not(.Mui-checked) svg': {
        opacity: 0,
      },
      '&:not(.Mui-checked):before': {
        content: '""',
        width: '20px',
        height: '20px',
        background: 'white',
        position: 'absolute',
        zIndex: 2,
        border: '1px solid',
        borderRadius: '100%',
        borderColor: globalColors.primary1.main
      },
      '&.Mui-checked': {
        opacity: 1,
      },
      '&.Mui-checked .MuiSvgIcon-root': {
        borderColor: globalColors.primary1.main
      },
      '&.Mui-checked:after': {
        background: globalColors.primary1.main
      },
    };
  }
);
