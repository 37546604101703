import { object, string } from "yup";

const validationSchema = object().shape({
  username: string()
    .required("login:email_invalid")
    .email("login:email_invalid"),
  password: string().required(" "),
});

export default validationSchema;
