// Base styles
import borders from "assets/theme/base/borders";
import breakpoints from "assets/theme/base/breakpoints";

// Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";

const { borderRadius } = borders;

// types
type Types = any;

const {
  values: { sm, md, lg, xl, xxl },
} = breakpoints;

const sidenav: Types = {
  styleOverrides: {
    root: {
      width: pxToRem(250),
      whiteSpace: "nowrap",
      border: "none",
    },

    paper: {
      width: pxToRem(250),
      height: `calc(100vh - ${pxToRem(32)})`,
      margin: pxToRem(16),
      borderRadius: borderRadius.xl,
      border: "none",
      [`@media (max-width: ${lg}px)`]: {
        borderRadius: `0 0 0 ${borderRadius.xl}`,
      },
    },

    paperAnchorDockedLeft: {
      borderRight: "none",
    },
  },
};

export default sidenav;
