import { FC, ReactNode, forwardRef } from "react";

// @mui material components
import { ButtonProps } from "@mui/material";

// Custom styles for MDButton
import MDButtonRoot from "components/MDButton/MDButtonRoot";

//  contexts
import { useMaterialUIController } from "context";

// Declaring props types for MDButton
export interface MDButtonProps extends Omit<ButtonProps, "color" | "variant"> {
  color?:
    | "white"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "contrast"
    | "light"
    | "dark"
    | "default"
    | "greenButton"
    | "black";
  variant?: "text" | "contained" | "outlined" | "gradient";
  size?: "small" | "medium" | "large";
  circular?: boolean;
  iconOnly?: boolean;
  children?: ReactNode;
  [key: string]: any;
}

const MDButton: FC<MDButtonProps> = forwardRef<any, MDButtonProps>(
  ({ color, variant, size, circular, iconOnly, children, ...rest }, ref) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    return (
      <MDButtonRoot
        {...rest}
        ref={ref}
        ownerState={{ color, variant, size, circular, iconOnly, darkMode }}
      >
        {children}
      </MDButtonRoot>
    );
  }
) as unknown as FC<MDButtonProps>;

// Declaring default props for MDButton
MDButton.defaultProps = {
  color: "white",
  variant: "gradient",
  size: "medium",
  circular: false,
  iconOnly: false,
};

export default MDButton;
