import { FC, forwardRef } from "react";

// Custom styles for MDRadio
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, RadioProps } from "@mui/material";
import { SelectItem } from "types/select";
import MDRadioRoot from "./MDRadioRoot";

type Props = RadioProps & {
  options: SelectItem[];
  value: string;
  label?: string;
};

const MDRadio: FC<Props> = forwardRef<any, Props>(({ disabled = false, value, options, ...rest }, ref) => (
  <FormControl disabled={disabled} fullWidth>
    {rest.label ? <FormLabel>{rest.label}</FormLabel> : null}
    <RadioGroup
      name={rest.name}
      value={value}
      onChange={rest.onChange as any}
      ref={ref}
    >
      <Box display="flex">
        {options.map(el => (
          <FormControlLabel
            sx={{ marginRight: '40px' }}
            key={el.value}
            value={el.value}
            checked={el.value === value}
            control={<MDRadioRoot size="small" color="primary" checked={el.value === value} />}
            label={el.label}
          />
        ))}
      </Box>
    </RadioGroup>
  </FormControl>
)) as unknown as FC<Props>;

export default MDRadio;

