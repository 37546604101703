// Base styles
import colors from "assets/theme/base/colors";
import * as stepperIndexStatic from "assets/components-static-styles/components/stepper/index";
import boxShadows from "assets/theme/base/boxShadows";

// Helper functions
import { merge } from "lodash";
import linearGradient from "assets/theme/functions/linearGradient";
import { globalColors } from "assets/colors-global";

const { transparent, gradients } = colors;
const { colored } = boxShadows;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    root: {
      background: globalColors.secondary8.main,
      boxShadow: colored.info,

      "&.MuiPaper-root": {
        backgroundColor: globalColors.secondary8.main,
      },
    },
  },
};

const stepper: Types = merge(themeStyles, stepperIndexStatic.default);

export default stepper;
