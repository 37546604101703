// Base styles
import { globalColors } from "assets/colors-global";
import * as sidenavStatic from "assets/components-static-styles/components/sidenav";
import colors from "assets/theme/base/colors";

// Helper functions
import { merge } from "lodash";
import linearGradient from "../functions/linearGradient";

const { white } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    paper: {
      backgroundColor: white.main,
    },
    ".MuiListItem-root :focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus":
      {
        backgroundColor: linearGradient(
          globalColors.secondary1.main,
          globalColors.secondary2.main,
          90
        ),
        color: globalColors.secondary1.main,
      },
  },
};

const sidenav: Types = merge(themeStyles, sidenavStatic.default);

export default sidenav;
