import MDBox from "components/MDBox";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import MDTypography from "components/MDTypography";
import { ReactComponent as CloseIcon } from 'assets/Icons/XIcon.svg';
import TwoFaImage from 'assets/images/2fa.png';
import MDButton from "components/MDButton";
import ReactCodeInput from 'react-code-input';
import MDButtonLoading from "components/MDLoadingButton";
import colors from "assets/theme/base/colors";

interface TwoFactorAuthProps {
  onSubmitCode: (code: string) => {},
  onCancel: () => void;
  submitting: boolean;
  previewScreen: boolean;
}

const TwoFaStep1 = ({ onNext }: { onNext: () => void }) => {
  const { t } = useTranslation();
  return (
    <>
      <MDBox display="flex" justifyContent="center" mb={3} mt={2}>
        <img src={TwoFaImage} alt="" height="200" />
      </MDBox>
      <MDBox>
        <MDTypography variant="h5" textAlign="center" mb={2}>
          {t('2fa_auth:almost_there')}
        </MDTypography>
        <MDTypography variant="body1" textAlign="center">
          {t('2fa_auth:msg')}
        </MDTypography>
        <MDBox mt={3} display="flex" justifyContent="center">
          <MDButton variant="contained" color="primary" onClick={onNext} fullWidth sx={{ maxWidth: 300 }}>
            {t('2fa_auth:start')}
          </MDButton>
        </MDBox>
      </MDBox>
    </>
  );
}

const TwoFaStep2 = ({ onNext, submitting }: { onNext: (code: string) => void, submitting: boolean }) => {
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  return (
    <>
      <MDBox display="flex" justifyContent="center" mb={1}>
        <MDTypography variant="h6">{t('2fa_auth:put_code')}</MDTypography>
      </MDBox>
      <MDBox display="flex" justifyContent="center" my={1}>
        <ReactCodeInput
          type="number"
          fields={6}
          name="code"
          inputMode="numeric"
          onChange={setCode}
          inputStyle={{
            height: 40,
            width: 40,
            paddingLeft: 15,
            margin: 10,
            fontSize: 16,
          }}
        />
      </MDBox>
      <MDBox mt={3} display="flex" justifyContent="center">
        <MDButtonLoading
          variant="contained"
          loading={submitting}
          disabled={code.length !== 6}
          color="primary"
          onClick={() => onNext(code)}
          fullWidth
          sx={{ maxWidth: 300 }}
        >
          {t('2fa_auth:send')}
        </MDButtonLoading>
      </MDBox>
    </>
  );
}

const TwoFactorAuth = ({ onSubmitCode, onCancel, submitting, previewScreen }: TwoFactorAuthProps): React.ReactElement => {
  const [step, setStep] = useState(0);

  const steps: Record<number, any> = useMemo(() => ({
    0: {
      content: <TwoFaStep1 onNext={() => setStep(prev => prev + 1)} />
    },
    1: {
      content: <TwoFaStep2 onNext={onSubmitCode} submitting={submitting} />
    }
  }), [onSubmitCode, submitting]);

  return (
    <BasicLayout>
      <MDBox zIndex={2} width={520} bgColor="white" p={2} pb={4} borderRadius="12px" shadow="xl" border="1px solid" borderColor={colors.grey[100]}>
        <MDBox sx={{ cursor: 'pointer' }}>
          <CloseIcon onClick={onCancel} />
        </MDBox>
        <MDBox>
          {steps[previewScreen ? step : 1]?.content}
        </MDBox>
      </MDBox>
    </BasicLayout>
  )
};

export default TwoFactorAuth;
