import { Box, OutlinedTextFieldProps, StandardTextFieldProps } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import _ from "lodash";
import { MuiTelInput } from "mui-tel-input";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReactComponent as VIcon } from 'assets/Icons/arrow-down.svg'


type InputType = OutlinedTextFieldProps | StandardTextFieldProps

// Declaring props types for MDInput
type Props = InputType & {
  name?: string;
  disabled?: boolean;
  required?: boolean;
  control?: any;
  showError?: boolean;
  maxLength?: number;
  max?: number;
}

export const InputForm = ({
  name,
  disabled,
  label,
  required,
  control,
  showError,
  variant,
  maxLength = 1000,
  max,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const { field, formState } = useController({
    name,
    control,
  });
  const error = _.get(formState.errors, name);

  const handleKeyDown = (e: any) => {
    if (rest?.type !== 'number') return;

    if (["e", "E", "+", "-"].includes(e.key)) { e.preventDefault(); }
    else if (!isNaN(e.key)) {
      if ((field?.value as string)?.length >= maxLength || max && Number(field.value + e.key) > max) {
        e.preventDefault();
      }
    }
  };

  if (rest.type === 'tel') {
    return (
      <MuiTelInput
        label={label}
        fullWidth
        margin="dense"
        required={required}
        disableFormatting
        unknownFlagElement={<Box ml={-1} display="flex" alignItems="center">
          <VIcon />
        </Box>}
        onBlur={field.onBlur}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{ maxLength }}
        InputLabelProps={{ shrink: true }}
        variant="standard"
        error={!!(error?.message as string)?.trim?.()}
        helperText={(error?.message as string)?.trim?.()}
        {...rest as any}
        value={field.value as any}
        onChange={field.onChange}
      />);
  }

  return (
    <MDBox flexDirection="column" display="flex" width={rest?.style?.width}>
      <MDInput
        fullWidth
        required={required}
        variant={variant ?? "standard"}
        size="small"
        disabled={disabled}
        name={field.name}
        error={!!error}
        label={label}
        inputRef={field.ref}
        value={field.value || ""}
        onBlur={field.onBlur}
        onKeyDown={handleKeyDown}
        onChange={field.onChange}
        {...rest}
        inputProps={{ maxLength }}
      />
      {showError && error && (
        <MDTypography
          variant="button"
          color="error"
          mt={"5px"}
          fontSize={12}
          style={{ lineHeight: "0 important" }}
        >
          {t(error.message as any)}
        </MDTypography>
      )}
    </MDBox>
  );
};
