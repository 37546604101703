// types
type Types = any;

const listItemText: Types = {
  styleOverrides: {
    root: {
      fontSize: "30px !important",
      marginTop: 0,
      marginBottom: 0,
      "&.MuiListItemText-root span": {
        fontWeight: "600",
      },
    },
  },
};

export default listItemText;
