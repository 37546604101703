import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import configuredStore from "store/store";
import { I18nextProvider } from "react-i18next";
import { Amplify } from "aws-amplify";
import i18n from "services/i18n/i18n";
import App from "App";
import { SnackbarProvider } from "notistack";
import "react-toastify/dist/ReactToastify.css";
import "./customStyles.css";
import awsExports from "services/aws";
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { pdfjs } from 'react-pdf';

import { MaterialUIControllerProvider } from "context";

Amplify.configure(awsExports);

const container = document.getElementById("root");
const root = (ReactDOM as any).createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <SnackbarProvider anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
    <Provider store={configuredStore}>
      <BrowserRouter>
        <MaterialUIControllerProvider>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </MaterialUIControllerProvider>
      </BrowserRouter>
    </Provider>
  </SnackbarProvider>
);

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
pdfjs.GlobalWorkerOptions.workerSrc = url