import { CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";

const LoadingSpinner = () => {
  return (
    <MDBox width={"100%"} height={"100vh"} display={"flex"}>
      <CircularProgress size="10vh" sx={{ margin: "auto" }} color="primary" />
    </MDBox>
  );
};

export default LoadingSpinner;
