import { OutlinedTextFieldProps, StandardTextFieldProps } from "@mui/material";
import MDBox from "components/MDBox";
import MultiSelect from "components/MultiSelect/MultiSelect";
import _ from "lodash";
import { useCallback } from "react";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SelectItem } from "types/select";


type InputType = OutlinedTextFieldProps | StandardTextFieldProps

// Declaring props types for MDInput
type Props = InputType & {
  name?: string;
  disabled?: boolean;
  required?: boolean;
  control?: any;
  showError?: boolean;
  maxLength?: number;
  options: SelectItem[];
}

export const MultiSelectForm = ({
  name,
  disabled,
  control,
  options,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const { field, formState } = useController({
    name,
    control,
  });

  const generateLabel = useCallback((val: string[]) => {
    const res = val.slice(0,3).map(el => options.find(e => el === e.value)?.label);
    return res.join(', ') + (val.length > 3 ? ` + ${val.length - 3}` : '')
  }, [options]);

  return (
    <MDBox flexDirection="column" display="flex" width={rest?.style?.width}>
      <MultiSelect
        name={field.name}
        value={field.value}
        onChange={field.onChange as any}
        options={options}
        valueToString={generateLabel} />
    </MDBox>
  );
};
