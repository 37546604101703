

import { FC, forwardRef } from "react";

// @mui material components
import { OutlinedTextFieldProps, StandardTextFieldProps } from "@mui/material";

// Custom styles for MDInput
import MDInputRoot from "components/MDInput/MDInputRoot";

type InputType = OutlinedTextFieldProps | StandardTextFieldProps

// Declaring props types for MDInput
type Props = InputType& {
  // variant?: "standard" | "outlined";
  error?: boolean;
  success?: boolean;
  disabled?: boolean;
  underline?: boolean;
}

const MDInput: FC<Props> = forwardRef<any, Props>(({ error, success, disabled, underline, ...rest }, ref) => (
  <MDInputRoot {...rest} ref={ref} ownerState={{ error, success, disabled, underline }} />
)) as unknown as FC<Props>;

// Declaring default props for MDInput
MDInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

export default MDInput;
