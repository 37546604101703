import { object, ref, string } from "yup";

export const ENGLISH_LETTERS_NUMBERS_SPECIAL_REGEX =
  /^[a-zA-Z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]*$/;
export const ONE_LOWERCASE_REGEX = /(?=.*[a-z])/;
export const ONE_UPPERCASE_REGEX = /(?=.*[A-Z])/;
export const ONE_SPECIAL_CHARACTER_REGEX =
  /(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+-])/;
export const MIN_MAX_LENGTH_REGEX = /^.{8,}$/;

const validationSchema = (requiredOldPassword: boolean) =>
  object().shape({
    newPassword: string()
      .required(" ")
      .matches(
        ENGLISH_LETTERS_NUMBERS_SPECIAL_REGEX,
        " "
      )
      .matches(ONE_LOWERCASE_REGEX, " ")
      .matches(ONE_UPPERCASE_REGEX, " ")
      .matches(ONE_SPECIAL_CHARACTER_REGEX, " ")
      .matches(MIN_MAX_LENGTH_REGEX, " "),
    confirmPassword: string()
      .required(" ")
      .equals([ref("newPassword")], "change_password:password_must_match"),
  });

export default validationSchema;
