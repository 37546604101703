import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/he";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import GlobalStyles from "@mui/material/GlobalStyles";
import { dayjs } from "services/dayjs";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { ReactComponent as CalendarRange } from "assets/Icons/calendar-range.svg";
import { globalColors } from "assets/colors-global";
import ArrowRight from "@mui/icons-material/KeyboardArrowRight";
import ArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { heIL } from "@mui/x-date-pickers/locales";
import { useAppSelector } from "store/hooks";
import { getIsMobile } from "store/slices/general";

const globalStyles = {
  "input.Mui-disabled": {
    "-webkit-text-fill-color": "inherit !important",
  },
  ".MuiInputBase-root": {
    "&:before": {
      "border-bottom-style": "solid !important",
    },
    ".Mui-disabled": { color: `${globalColors.secondary2.main} !important` },
  },
  ".MuiPickersMonth-monthButton": {
    "font-size": "16px !important",
    "&.Mui-selected:hover": {
      "background-color": `${globalColors.primary1.main} !important`,
    },
    "&.Mui-selected:focus": {
      "background-color": `${globalColors.primary1.main} !important`,
    },
  },
  ".MuiPickersYear-yearButton": {
    "font-size": "16px !important",
    "&.Mui-selected:hover": {
      "background-color": `${globalColors.primary1.main} !important`,
    },
    "&.Mui-selected:focus": {
      "background-color": `${globalColors.primary1.main} !important`,
    },
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "33px !important",
    fontWeight: "bold !important",
    fontSize: "14px !important",
    padding: "0px !important",
    color: `${theme.palette.text.primary} !important`,
    "&": {
      borderColor: `${theme.palette.text.primary} !important`,
    },
  },
  notchedOutline: {
    borderColor: `${theme.palette.text.primary} !important`,
  },
  disabledDatePicker: {
    opacity: 0.5,
  },
  inputClass: {
    padding: `0 10px 0 0 !important`,
    width: "100%",
    color: `${theme.palette.text.primary} !important`,
  },
  end: {
    padding: `10px !important`,
    marginLeft: "0px",
    // width: "100%",
    color: `${theme.palette.text.primary} !important`,
  },
  cursorPointer: {
    cursor: "pointer !important",
  },
}));

const DatePickerValue = ({
  value,
  defaultValue,
  onChange,
  maxDate,
  minDate,
  label,
  slotProps,
  customStyles,
  ...rest
}: any) => {
  const [date, setDate] = React.useState<dayjs.Dayjs>(
    defaultValue ? dayjs(defaultValue, "YYYY-MM-DD") : dayjs()
  );
  const [open, setOpen] = React.useState(false);
  const classes = useStyles({});
  const isMobile = useAppSelector(getIsMobile);

  const toDayjs = (date: any) => {
    if (date) {
      return dayjs.isDayjs(date) ? date : dayjs(date);
    }
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="he"
      localeText={
        heIL.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <GlobalStyles styles={{ ...globalStyles, ...customStyles }} />
      <DatePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        className={rest.disabled ? classes.disabledDatePicker : ""}
        label={label}
        slots={{
          rightArrowIcon: ArrowLeft,
          leftArrowIcon: ArrowRight,
          openPickerIcon: CalendarRange,
        }}
        slotProps={{
          field: {
            disabled: !isMobile,
            className: rest.disabled ? "" : classes.cursorPointer,
            onClick: () => {
              if (!rest.disabled) {
                setOpen(true);
              }
            },
          },
          openPickerButton: {
            classes: { root: classes.end },
          },
          textField: {
            InputProps: {
              classes: {
                root: classes.root,
                notchedOutline: classes.notchedOutline,
                input: classes.inputClass,
              },
            },
          },
          ...slotProps,
        }}
        value={toDayjs(value) || date}
        onChange={(val: any) => {
          onChange(val);
          setDate(val);
        }}
        format={"DD/MM/YY"}
        maxDate={toDayjs(maxDate)}
        minDate={toDayjs(minDate)}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default DatePickerValue;
