// @mui material components
import Card from "@mui/material/Card";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import MDBox from "components/MDBox";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import { useTranslation } from "react-i18next";
import validationSchema from "./validation";
import MDFormField from "components/MDFormField";
import MDTypography from "components/MDTypography";
import pxToRem from "assets/theme/functions/pxToRem";
import MDButtonLoading from "components/MDLoadingButton";
import useUpdateUserDetails from "./useUpdateUserDetails.hook";
import { useEffect } from "react";
import { UpdateUserDetailsValue } from "./types";
import { Auth } from "aws-amplify";

const UpdateUserDetailsPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { onSubmit, submitting } = useUpdateUserDetails();

  const form = useForm<UpdateUserDetailsValue>({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  useEffect(() => {
    (async () => {
      const user = await Auth.currentAuthenticatedUser();
      form.setValue('email', user.attributes.email);
      form.setValue('phone', user.attributes.phone_number);
    })();
  }, [])

  return (
    <BasicLayout>
      <Card style={{ width: 400, maxWidth: "90%", margin: "auto" }}>
        <MDBox
          dir="rtl"
          textAlign="center"
          bgColor="grey-100"
          borderRadius="xl"
          p={3}
          mx={2}
          mt={-3}
        >
          <MDTypography
            fontWeight="bold"
            fontSize={pxToRem(22)}
            color="secondary"
          >
            {t("update_user_details:header")}
          </MDTypography>
          <MDBox mt={1}>
            <MDTypography
              color="secondary"
            >
              {t("update_user_details:subheader")}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox
          pt={2.5}
          pb={5}
          px={3}
          width="100%"
          component="form"
          role="form"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <MDBox pt={3}>              
            <MDFormField
              fieldKey="company"
              customLabel={t("update_user_details:company_name")}
              form={form}
              required={true}
              showError={false}
              maxLength={50}
              disabled={submitting}
            />
          </MDBox>
          <MDBox>              
            <MDFormField
              fieldKey="first_name"
              customLabel={t("update_user_details:first_name")}
              form={form}
              required={true}
              showError={false}
              maxLength={50}
              disabled={submitting}
            />
          </MDBox>
          <MDBox>              
            <MDFormField
              fieldKey="last_name"
              customLabel={t("update_user_details:last_name")}
              form={form}
              required={true}
              showError={false}
              maxLength={50}
              disabled={submitting}
            />
          </MDBox>
          <MDBox>              
            <MDFormField
              fieldKey="email"
              customLabel={t("update_user_details:email")}
              form={form}
              required={true}
              type="email"
              disabled
              maxLength={50}
            />
          </MDBox>
          <MDBox>              
            <MDFormField
              fieldKey="phone"
              customLabel={t("update_user_details:phone")}
              form={form}
              showError={false}
              type="text"
              maxLength={20}
              disabled
            />
          </MDBox>

          <MDBox mt={4}>
            <MDButtonLoading
              loading={submitting}
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={!form.formState.isValid}
              onSubmit={form.handleSubmit(onSubmit)}
            >
              {t("update_user_details:submit_btn")}
            </MDButtonLoading>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
};

export default UpdateUserDetailsPage;
