import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "store/hooks";
import { updateUserData } from "store/slices/users-data";
import { UpdateUserDetailsValue } from "./types";

import { toastifyError } from "utils/toastify-message";
import { useNavigate } from "react-router-dom";

const useUpdateUserDetails = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [userUpdated, setUserUpdated] = useState(false);
  const dispatch = useAppDispatch();
  const [key, setKey] = useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleErrorSignUp = (
    errorMessage: string | undefined,
  ) => {
    toastifyError(t(errorMessage));
  };

  const onSubmit = async (data: UpdateUserDetailsValue) => {
    setUserUpdated(false);
    try {
      setSubmitting(true);
      const payload = {
        ...data,
        phone: data.phone ? data.phone.replace(/ /g, '') : undefined
      }

      const res = await dispatch(updateUserData(payload)).unwrap();
      setKey(res.encrypted_license);
      setUserUpdated(true);
      navigate('/home');
    } catch (error: any) {
      handleErrorSignUp(error?.message);
      // addLoginLog(data.username, AuditLogStatus.Failed, error);
    } finally {
      setSubmitting(false);
    }
  };
  return { submitting, userUpdated, key, onSubmit };
};

export default useUpdateUserDetails;
