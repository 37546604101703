import { LanguageType } from "types/language";

const Languages: LanguageType[] = [
  {
    key: "en",
    label: "English",
    serverKey: "ENGLISH",
    isRtl: false,
    source: require("../../assets/locales/en.json"),
  },
  {
    key: "he",
    label: "Hebrew",
    serverKey: "HEBREW",
    isRtl: true,
    source: require("../../assets/locales/he.json"),
  },
];

export default Languages;
