import {
  PayloadAction,
    createSlice,
  } from "@reduxjs/toolkit";
  import { RootState } from "store/store";
  
  const BASE = "auth";
  
  // Initial State
  
  const initialState = {
    isLoginAllowed: true,
  };
  
  // Slice
  
  const generalSlice = createSlice({
    name: BASE,
    initialState:initialState,
    reducers: {
      setIsLoginAllowed: (state, action: PayloadAction<boolean>) => ({
        ...state,
        isLoginAllowed: action.payload,
      }),
    }, 
  });
  
  export const { setIsLoginAllowed } = generalSlice.actions;
  
  export const getIsMobile = (state: RootState) => state.general.isMobile;
  
  export default generalSlice;
  