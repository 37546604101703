import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import PageContentContainer from "components/PageContainers/PageContentContainer";
import PageHeaderContainer from "components/PageContainers/PageHeaderContainer";
import { useTranslation } from "react-i18next";
import { ContextType, pdfSources } from "constants/pdf";
import { Document, Page } from 'react-pdf';
import { useCallback, useState } from "react";

interface PDFFIleReaderPage {
  context: ContextType;
}

const PDFFIleReaderPage = ({ context }: PDFFIleReaderPage) => {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState<number>();

  const onDocumentLoadSuccess = useCallback(({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  }, []);

  return (
    <DashboardLayout>
      <PageHeaderContainer>
        <DashboardNavbar pageTitle={t(pdfSources[context]?.pageTitle)} />
      </PageHeaderContainer>
      <PageContentContainer>
        <Document file={pdfSources[context].link} onLoadSuccess={onDocumentLoadSuccess} loading=" ">
          {Array.from({ length: numPages }).map((_, ind) => (
            <Page pageNumber={ind + 1} renderTextLayer={true} renderAnnotationLayer={false} scale={1.5} />
          ))}
        </Document>
      </PageContentContainer>
    </DashboardLayout>
  );
};

export default PDFFIleReaderPage;
