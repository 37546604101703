export const globalColors = {
  primary1: {
    main: "#ED2B2E", // SuperPharmBO color2 - Blue (usage for the primary color)
  },
  primary2: {
    main: "#000",
  },
  primary3: {
    main: "#FFFFFF", // SuperPharmBO color3
  },
  primary4: {
    main: "#F8F9FA",
  },
  secondary1: {
    main: "#000", // SuperPharmBO color1 (usage for the secondary color)
  },
  secondary2: {
    main: "#262C39", // SuperPharmBO color10 - Dark blue (usage for sidenav and text color)
  },
  secondary3: {
    main: "#7B809A",
  },
  secondary4: {
    main: "#E4E8EE",
  },
  secondary5: {
    main: "#F88317", // SuperPharmBO color5
  },
  secondary6: {
    main: "#F44334",
  },
  secondary7: {
    main: "#6ACD7A", // SuperPharmBO - success green
  },
  secondary8: {
    main: "#E3E8EE", // SuperPharmBO color6,
  },
  darkPass: {
    main: "#323743",
  },
  error: {
    main: "#EC2531", // SuperPharmBO - error red
  },
  statusINACTIVE: {
    text: "#7B809A",
    bg: 'rgba(123, 128, 154, 0.20)'
  },
  statusACTIVE: {
    text: "#4CAF50",
    bg: 'rgba(76, 175, 80, 0.20)'
  },
  statusPENDING: {
    text: "#F0A54E",
    bg: 'rgba(240, 165, 78, 0.20)'
  },
  statusAPPROVED: {
    text: "#4CAF50",
    bg: 'rgba(76, 175, 80, 0.20)'
  },
  statusREJECTED: {
    text: "#F33F5C",
    bg: 'rgba(243, 63, 88, 0.20)'
  },
  sidebar: {
    main: "#fff",
    text: '#151515CC',
    activeText: '#ED2B2E'
  }
};
