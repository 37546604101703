import { useLayoutEffect, useState } from "react";
import debounce from "lodash/debounce";
// Base styles
import breakpoints from "assets/theme/base/breakpoints";
import { useAppDispatch } from "store/hooks";
import { setIsMobile } from "store/slices/general";

export const isMobileFunc = () => window.innerWidth < breakpoints.values.lg;

const useIsMobile = () => {
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    const updateSize = (): void => {
      dispatch(setIsMobile(isMobileFunc()));
    };
    console.log("addEventListener");

    window.addEventListener("resize", debounce(updateSize, 250));

    return (): void => window.removeEventListener("resize", updateSize);
  }, []);
};

export default useIsMobile;
