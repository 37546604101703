import { OutlinedTextFieldProps, StandardTextFieldProps } from "@mui/material";
import MDBox from "components/MDBox";
import MDRadio from "components/MDRadio";
import _ from "lodash";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SelectItem } from "types/select";


type InputType = OutlinedTextFieldProps | StandardTextFieldProps

// Declaring props types for MDInput
type Props = InputType & {
  name?: string;
  disabled?: boolean;
  required?: boolean;
  control?: any;
  showError?: boolean;
  maxLength?: number;
  options: SelectItem[];
  label?: string;
}

export const MDRadioForm = ({
  name,
  disabled,
  control,
  label,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const { field, formState } = useController({
    name,
    control,
  });

  return (
    <MDBox flexDirection="row" display="flex" justifyContent="space-between" width={rest?.style?.width}>
      <MDRadio
        label={label}
        disabled={disabled}
        name={field.name}
        ref={field.ref}
        value={field.value}
        onBlur={field.onBlur}
        onChange={field.onChange}
        options={rest.options}
    />
    </MDBox>
  );
};
