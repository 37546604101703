// Base styles
import * as staticOutlined from "assets/components-static-styles/components/button/outlined";
import { merge } from "lodash";

const themeStyles = {
  base: {
    padding: "4px 10px",
  },
  primary: {},
  secondary: {},
};

const mergedStyles = merge(themeStyles, staticOutlined.default);

const outlined = {
  ...mergedStyles,
};

export default outlined;
