/* eslint-disable no-unused-vars */

import MDBox from "components/MDBox";
import { UseFormReturn } from "react-hook-form";
import MDTypography from "components/MDTypography";
import { InputForm } from "components/MDInputForm";
import {
  IconButton,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  Tooltip,
} from "@mui/material";
import { AutocompleteForm } from "components/MDAutocompleteForm";
import { t } from "i18next";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mui/material/Icon";
import { useEffect, useState } from "react";
import { useMaterialUIController } from "context";
import DatePickerForm from "components/MDDatePickerForm/DatePickerForm";
import ControlledCheckbox from "components/ControlledCheckbox/ControlledCheckbox";
import pxToRem from "assets/theme/functions/pxToRem";
import { SelectItem } from "types/select";
import ControlledToggle from "components/ControlledToggle/ControlledToggle";
import { MDRangeForm } from "components/MDRangeForm/MDRangeForm";
import { MDRadioForm } from "components/MDRadioForm/MDRadioForm";
import { MultiSelectForm } from "components/MultiSelectForm/MultiSelectForm";
import { ReactComponent as InfoIcon } from "assets/Icons/info.svg";

type FormFieldTypes =
  | "select"
  | "datePicker"
  | "text"
  | "number"
  | "toggle"
  | "checkbox"
  | "password"
  | "password_s"
  | "email"
  | "tel"
  | "range"
  | "multiSelect"
  | "radio";
type InputType = OutlinedTextFieldProps | StandardTextFieldProps;

// Declaring props types for MDInput
type Props = InputType & {
  type?: FormFieldTypes;
  label?: string;
  customLabel?: string;
  fieldKey: string;
  form: UseFormReturn<any, any>;
  required?: boolean;
  options?: {
    items: SelectItem[];
    addLabel?: string;
    addFunc?: (value: string) => void;
  };
  minDate?: Date;
  customErrors?: { [key: string]: { message: string } };
  maxDate?: Date;
  variant?: "outlined" | "standard";
  showError?: boolean;
  maxLength?: number;
  [key: string]: any;
  multiple?: boolean;
  min?: number;
  max?: number;
  tooltip?: string;
};

function MDFormField<T>({
  showError = true,
  variant = "standard",
  type = "text",
  required,
  fieldKey,
  label,
  options,
  customErrors,
  customLabel,
  minDate,
  maxDate,
  maxLength,
  multiple,
  min,
  max,
  tooltip,
  form: {
    setValue,
    control,
    register,
    formState: { errors = {}, isValid, isDirty },
  },
  ...rest
}: Props): React.ReactElement {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [controller] = useMaterialUIController();

  const error = extractNestedFieldError(fieldKey, errors);
  const customError = customErrors ? customErrors[fieldKey] : null;
  
  const getPasswordProps = () => {
    if (type === "password") {
      return {
        type: showPassword ? "text" : "password",
        InputProps: {
          endAdornment: (
            <InputAdornment
              position="start"
              onClick={() => setShowPassword(!showPassword)}
              sx={
                errors[fieldKey] &&
                variant === "outlined" && { marginRight: "1rem" }
              }
            >
              <Icon
                fontSize="small"
                color={controller.darkMode ? "info" : "inherit"}
                style={{ cursor: "pointer" }}
              >
                {showPassword ? "visibility" : "visibility_off"}
              </Icon>
            </InputAdornment>
          ),
        },
      };
    }
  };

  const renderFieldError = () => {
    return customError || error ? (
      <MDTypography
        style={{ lineHeight: "0 important" }}
        variant="button"
        color="error"
        fontSize={12}
      >
        {customError ? customError?.message : t(error.message as string)}
      </MDTypography>
    ) : null;
  };

  const renderSelect = () => {
    return (
      <AutocompleteForm
        options={options.items}
        addFunc={options.addFunc}
        addLabel={options.addLabel}
        setValue={setValue}
        name={fieldKey}
        label={label}
        required={required}
        variant={variant}
        control={control}
        multiple={multiple}
        {...rest}
      />
    );
  };

  const renderMultiSelect = () => {
    return (
      <MultiSelectForm
        options={options.items}
        name={fieldKey}
        label={label}
        control={control}
        onChange={rest.onChange}
      />
    );
  }

  const renderDatePicker = () => {
    return (
      <DatePickerForm
        name={fieldKey}
        label={label}
        required={required}
        minDate={minDate}
        maxDate={maxDate}
        control={control}
        {...rest}
      />
    );
  };

  const renderToggle = () => {
    return (
      <ControlledToggle
        name={fieldKey}
        control={control}
        setValue={setValue}
        {...rest}
      />
    );
  };

  const renderCheckbox = () => {
    return (
      <ControlledCheckbox
        name={fieldKey}
        control={control}
        label={label}
        {...rest}
      />
    );
  };

  const renderInput = () => {
    return (
      <InputForm
        error={showError && !!error}
        variant={variant}
        required={required}
        name={fieldKey}
        label={label}
        InputLabelProps={{ shrink: true }}
        control={control}
        fullWidth
        type={type}
        maxLength={maxLength}
        {...rest}
        // for type 'password'
        {...getPasswordProps()}
      />
    );
  };

  const renderRange = () => {
    return <MDRangeForm
      name={fieldKey}
      control={control}
      min={min}
      max={max}
    />
  }

  const renderRadioGroup = () => {
    return <MDRadioForm
      label={label}
      name={fieldKey}
      control={control}
      options={options.items}
    />
  }

  const renderCustomLabel = () => {
    return (
      <MDBox display={"flex"} alignItems={'baseline'}>
        <MDTypography
          sx={{ marginBottom: pxToRem(2) }}
          variant={"caption2"}
          color={"primary"}
          fontSize="14px"
        >
          {customLabel}
        </MDTypography>
        {required && (
          <MDTypography
            variant={"caption2"}
            sx={{
              marginLeft: pxToRem(3)
            }}
            fontWeight={"medium"}
            color="error"
          >
            *
          </MDTypography>
        )}
        {tooltip ? <Tooltip title={tooltip} placement="top">
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip> : null}
      </MDBox>
    );
  };

  return (
    <MDBox mb={2}>
      {customLabel && renderCustomLabel()}
      <MDBox minHeight={pxToRem(30)}>
        {(type == "select" && renderSelect()) ||
          (type == "datePicker" && renderDatePicker()) ||
          (type == "checkbox" && renderCheckbox()) ||
          (type == "toggle" && renderToggle()) ||
          (type == "range" && renderRange()) ||
          (type == "multiSelect" && renderMultiSelect()) ||
          (type == "radio" && renderRadioGroup()) ||
          renderInput()}
      </MDBox>
      {showError && (customError || error) && renderFieldError()}
    </MDBox>
  );
}

const extractNestedFieldError = (nestedKey: string, errors: any) => {
  let error = errors;
  nestedKey.split(".").forEach((key) => {
    error = error ? error[key] : null;
  });

  return error;
};

export default MDFormField;
