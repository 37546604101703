import { useController } from "react-hook-form";
import _ from "lodash";
import DatePickerValue from "components/DatePicker/DatePicker";

interface Props {
  name?: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  control?: any;
  showError?: boolean;
  maxDate?: Date;
  minDate?: Date;
}

const DatePickerForm = ({
  name,
  label,
  control,
  maxDate,
  minDate,
  ...rest
}: Props) => {
  const { field, formState } = useController({
    name,
    control,
  });

  return (
    <DatePickerValue
      label={label}
      value={field.value}
      onChange={(e: any) => field.onChange(e)}
      maxDate={maxDate}
      minDate={minDate}
      {...rest}
    />
  );
};

export default DatePickerForm;
