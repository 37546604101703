import { Checkbox } from "components/Checkbox/Checkbox";
import TabToggleButton from "components/ToggleTabs/TabToggleButton";
import TabsToggleContainer from "components/ToggleTabs/TabsToggleContainer";
import { forwardRef, useState } from "react";
import { useController } from "react-hook-form";

const ControlledToggle = ({ name, setValue, control, label0, label1, ...rest }: any) => {
    const { field } = useController({
        name,
        control
    });

    const handleToggleChange = (e: any) => {  
        if(rest.onChange){
            rest.onChange((1 - (field.value == 1 ? 1 : 0)))
        }
        setValue(name, (1 - (field.value == 1 ? 1 : 0)), {
            shouldDirty: true,
            shouldValidate: true,
            shouldTouch: true
        });
    }    

    return (
        <TabsToggleContainer value={field.value == 1 ? 1 : 0} onChange={handleToggleChange} onBlur={field.onBlur}>
            <TabToggleButton id={"0"}  label={label0}></TabToggleButton>
            <TabToggleButton id={"1"}  label={label1}></TabToggleButton>
        </TabsToggleContainer>
    );
};

export default ControlledToggle;
