import { OutlinedTextFieldProps, StandardTextFieldProps } from "@mui/material";
import MDBox from "components/MDBox";
import MDRange from "components/MDRange";
import MDTypography from "components/MDTypography";
import _ from "lodash";
import { useMemo } from "react";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";


type InputType = OutlinedTextFieldProps | StandardTextFieldProps

// Declaring props types for MDInput
type Props = InputType & {
  name?: string;
  disabled?: boolean;
  required?: boolean;
  control?: any;
  showError?: boolean;
  maxLength?: number;
  min?: number;
  max?: number;
}

export const MDRangeForm = ({
  name,
  disabled,
  control,
  min = 20,
  max = 120,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const { field, formState } = useController({
    name,
    control,
  });

  const rangeNumbers = useMemo(() => {
    const start = Math.round(min / 10) * 10;
    const delta = Math.round((max - min) / 50) * 10;
    const numberCount = Math.round((max - min) / delta) + 1;
    return Array.from({ length: numberCount }).map((_, ind) => start + ind * delta);
  }, [min, max]);

  return (
    <MDBox flexDirection="column" display="flex" width={rest?.style?.width}>
      <MDRange
        size="small"
        disabled={disabled}
        name={field.name}
        ref={field.ref}
        value={field.value}
        onBlur={field.onBlur}
        onChange={field.onChange}
        step={1}
        min={min}
        max={max}
    />
    <MDBox display="flex" justifyContent="space-between">
      {rangeNumbers.map(el => (
        <MDTypography key={el} fontSize="10px">{el}</MDTypography>
      ))}
    </MDBox>
    </MDBox>
  );
};
