import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { t } from "i18next";
import http from "services/http/http";
import { BaseListState } from "store/base";
import { RootState } from "store/store";
import ApiException, { ApiSearchQueryParams, ApiSearchResult } from "types/api";
import { User } from "interfaces/user";
import { UserDataRole } from "types/user-data";
import { Auth } from "aws-amplify";
import { UpdateUserDetailsValue } from "layouts/Main/UpdateUserDetails/types";

const BASE = "usersData";

// Adapter

const UsersAdapter = createEntityAdapter<User>();

// Initial State
interface UsersState extends BaseListState {
  loggedUser: User | null;
  users: EntityState<User> | undefined;
  userClientId: number | string | null;
}

const initialState: UsersState = {
  users: UsersAdapter.getInitialState(),
  loggedUser: null,
  userClientId: null,
  loading: "idle",
};

// Slice

const usersSlice = createSlice({
  name: BASE,
  initialState: UsersAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(forceResetUserPassword.fulfilled, (state, action) => {})
      .addCase(forceResetUserPassword.rejected, (state, action) => {})
      .addCase(fetchLoggedUser.fulfilled, (state, action) => {
        state.loggedUser = action.payload;
        console.log({ action });
      })
      .addCase(updateUserData.fulfilled, (state, action) => {
        state.loggedUser = action.payload;
      })
  },
});

export const fetchUsers = createAsyncThunk(
  `${BASE}/fetchUsers`,
  async (
    {
      searchParams,
      filterObj,
    }: {
      searchParams?: ApiSearchQueryParams<User>;
      filterObj?: Partial<User>;
    },
    { rejectWithValue }
  ) => {
    return await http
      .request<ApiSearchResult<User>>({
        key: "searchUsers",
        queryParams: searchParams,
        data: filterObj,
      })
      .then((res) => res)
      .catch((err: ApiException) => rejectWithValue(err));
  }
);

export const fetchLoggedUser = createAsyncThunk<User>(
  `${BASE}/fetchLoggedUser`,
  async (_) => {
    try {
      const res = await http
        .request<any>({
          key: "getLoggedUser",
        });
      if (res?.body?.presigned_url) {
        const data = await fetch(res.body.presigned_url);
        return data.json();
      }
    } catch {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      return {
        data: { email: cognitoUser.attributes.email }
      } as User;
    }
  }
);

export const updateUserData = createAsyncThunk<User, UpdateUserDetailsValue>(
  `${BASE}/updateUserData`,
  async (data: UpdateUserDetailsValue, { rejectWithValue }) => { 
    try {
      const res = await http
        .request<any>({
          key: "updateUserData",
          data,
        });
  
      if (res?.body?.presigned_url) {
        const data = await fetch(res.body.presigned_url);
        return data.json()
      }
  
    } catch(err: any) {
      return rejectWithValue(err);
    }  
  }
);

export const forceResetUserPassword = createAsyncThunk(
  `${BASE}/forceResetUserPassword`,
  async (username: string, { rejectWithValue }) => {
    return await http
      .request<void>({
        key: "forceResetPassword",
        data: { username },
      })
      .then((res) => res)
      .catch((err: ApiException) => rejectWithValue(err));
  }
);

export const getUserAttributes = createAsyncThunk(
  `${BASE}/getUserAttributes`,
  async (username: string, { rejectWithValue }) => {
    return await http
      .request<{ Name: string; Value: string }[]>({
        key: "getUserAttributes",
        data: { username },
      })
      .then((res) => res)
      .catch((err: ApiException) => rejectWithValue(err));
  }
);

export const checkPasswordReuse = createAsyncThunk(
  `${BASE}/checkPasswordReuse`,
  async (data: { username: string; password: string }, { rejectWithValue }) => {
    return await http
      .request<boolean>({
        key: "checkPasswordReuse",
        data,
      })
      .then((res) => res)
      .catch((err: ApiException) => rejectWithValue(err));
  }
);

export const saveOldPassword = createAsyncThunk(
  `${BASE}/saveOldPassword`,
  async (data: { username: string; password: string }, { rejectWithValue }) => {
    return await http
      .request<void>({
        key: "saveOldPassword",
        data,
      })
      .then((res) => res)
      .catch((err: ApiException) => rejectWithValue(err));
  }
);

// Selectors

export const usersSelectors = UsersAdapter.getSelectors(
  (state: RootState) => state.users.users
);

export const selectLoggedUser = (state: RootState) => state.users.loggedUser;
export const selectLoggedUserClientId = (state: RootState) =>
  state.users.userClientId;
export const selectUserRole = (state: RootState) =>
  UserDataRole.PORTAL;

export default usersSlice;
