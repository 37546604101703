import { Checkbox } from "components/Checkbox/Checkbox";
import { forwardRef } from "react";
import { useController } from "react-hook-form";

const ControlledCheckbox = ({ name, control, defaultValue, ...rest }: any) => {
  const { field } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <Checkbox
      value={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      {...rest}
    />
  );
};

export default ControlledCheckbox;
