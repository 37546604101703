import { SxProps, TextField } from "@mui/material"
import pxToRem from "assets/theme/functions/pxToRem"
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"

type ReadOnlyFieldProps = {
    label: string,
    value: any,
    fontWeight?: 'medium' | 'regular',
    sx?: SxProps;
    elipsis?: boolean;
}

const ReadOnlyField = ({ label, value, fontWeight = 'medium', sx, elipsis = true }: ReadOnlyFieldProps) => {
    return <MDBox className="ReadonlyField-root" display={'flex'} flexDirection={'column'} sx={sx}>
        {label &&
            <MDTypography
                className={'ReadonlyField-label'}
                sx={{ marginBottom: pxToRem(2), width: 'max-content', height: pxToRem(15) }}
                variant={"caption2"}
                color="primary" >
                {label}
            </MDTypography>
        }
        <MDTypography
            className={'ReadonlyField-value'}
            sx={{ marginBottom: pxToRem(16), minHeight: pxToRem(30), padding: '4px 0 4px 0px', display: "flex", alignItems: 'center', overflow: 'hidden' }}
            variant={"caption"}
            color="primary"
            fontWeight={fontWeight}
        >
            {elipsis ? <MDBox overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" maxWidth="95%">
                {value || '-'}
            </MDBox> : <MDTypography fontWeight={fontWeight} noWrap={false} sx={{ wordBreak: 'break-word' }}> {value || '-'}</MDTypography>}
            
        </MDTypography>
    </MDBox>
}

export default ReadOnlyField