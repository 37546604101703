

// types
type Types = any;

const link: Types = {
  defaultProps: {
    // underline: "none",
    // color: "inherit",
  },
};

export default link;
