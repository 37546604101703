import { Tabs, Theme, styled } from "@mui/material";
import borders from "assets/theme/base/borders";
import pxToRem from "assets/theme/functions/pxToRem";

const { borderRadius } = borders;

const TabsToggleContainer = styled(Tabs)(({ theme }: { theme: Theme }) => {
  return {
    backgroundColor: `${theme.palette.background.paper} !important`,
    borderRadius: borderRadius.lg,
    minHeight: "unset !important",
    padding: pxToRem(4),

    ".MuiTabs-flexContainer": {
      height: "100%",
      zIndex: 10,
    },

    ".MuiTabs-fixed": {
      overflow: "unset !important",
    },

    ".MuiTabs-vertical": {
      "& .MuiTabs-indicator": {
        width: "100% !important",
      },
    },

    ".MuiTabs-indicator": {
      height: "100% !important",
      pointerEvents: 'none',
      borderRadius: borderRadius.lg,
      backgroundColor: 'transparent !important',
      boxShadow: theme.palette.tabs.indicator.boxShadow,
      transition: "all 500ms ease !important",
    },
  };
});

export default TabsToggleContainer;
