// Base styles
import borders from "assets/theme/base/borders";
import typography from "assets/theme/base/typography";

// Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";

const { borderRadius } = borders;
const { size } = typography;

// types
type Types = any;

const inputOutlined: Types = {
  styleOverrides: {
    root: {
      height: 41,
      fontSize: size.xs,
      borderRadius: borderRadius.md,
    },

    input: {
      padding: pxToRem(12),
    },

    inputSizeSmall: {
      fontSize: size.xs,
      padding: pxToRem(10),
    },

    multiline: {
      padding: 0,
    },
  },
};

export default inputOutlined;
