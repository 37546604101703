import { FC, forwardRef } from "react";

// Custom styles for MDRange
import MDRangeRoot from "components/MDRange/MDRangeRoot";
import { SliderProps } from "@mui/material";

type Props = SliderProps;

const MDRange: FC<Props> = forwardRef<any, Props>(({ disabled = false, min, max, ...rest }, ref) => (
  <MDRangeRoot {...rest} ref={ref}
  onChange={() => {}}
  valueLabelDisplay="on"
  size="small"
  min={min}
  max={max}
  step={1}
  {...rest}
  />
)) as unknown as FC<Props>;

export default MDRange;

