import { Advertiser } from './../../interfaces/advertiser';
import {
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { Country } from "interfaces/countries";
import http from "services/http/http";
import { RootState } from "store/store";
import ApiException from "types/api";
import { ConfigState } from "types/store";

const BASE = "config";

const initialState: ConfigState = {
  selectedLanguage: "en",
  countries: [],
  advertisers: [],
};

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(updateSelectedLangauge.fulfilled, (state, action) => {
      state.selectedLanguage = action.payload.selectedLanguage;
    });
    builder.addCase(fetchSelectedLangauge.fulfilled, (state, action) => {
      state.selectedLanguage = action.payload.selectedLanguage;
    });
    builder.addCase(fetchCountries.fulfilled, (state, action) => {
      state.countries = action.payload;
    });
    builder.addCase(fetchAllAdvertisers.fulfilled, (state, action) => {
      state.advertisers = action.payload;
    });
  },
});



export const fetchSelectedLangauge = createAsyncThunk(
  `${BASE}/fetchSelectedLangauge`,
  async (_, { rejectWithValue }) => {
    return await http
      .request<ConfigState>({
        key: "getSelectedLangauge",
      })
      .then((res) => res)
      .catch((err: ApiException) => rejectWithValue(err));
  }
);

export const fetchCountries = createAsyncThunk(
  `${BASE}/fetchCountries`,
  async (_, { rejectWithValue }) => {
    return http
      .request<Country[]>({
        key: "getCountries",
        queryParams: { ordering: 'name' }
      })
      .then((res) => res)
      .catch((err: ApiException) => rejectWithValue(err));
  }
);

export const updateSelectedLangauge = createAsyncThunk(
  `${BASE}/updateSelectedLangauge`,
  async (data: Partial<ConfigState>, { rejectWithValue }) => {
    return await http
      .request<ConfigState>({
        key: "updateSelectedLangauge",
        data,
      })
      .then((res) => {
        console.log(res); return res})
      .catch((err: ApiException) => rejectWithValue(err));
  }
);

export const fetchAllAdvertisers = createAsyncThunk(
  `${BASE}/fetchAllAdvertisers`,
  async (_, { rejectWithValue }) => {
    return http
      .request<Advertiser[]>({
        key: "getAdvertisers",
        queryParams: { ordering: 'name' }
      })
      .catch((err: ApiException) => rejectWithValue(err));
  }
);

export const getSelectedLanguage = (state: RootState) => state.config.selectedLanguage;

export default configSlice;
