import { Auth } from "aws-amplify";
import { toNumber } from "lodash";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { setIsLoginAllowed } from "store/slices/auth";
import { forceResetUserPassword } from "store/slices/users-data";
import { ResetPageState, ResetPageStateSource } from "types/auth";
// import { addLoginLog } from "utils/server-log.utils";
import { toastifyError } from "utils/toastify-message";

export interface LoginFormData {
  username: string;
  password: string;
}

const MAX_LOGIN_ATTEMPTS = 100;
const MAX_LOGIN_ATTEMPTS_TIME = 60 * 60 * 1000; // an hour

const useLogin = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [is2FaOpened, setIs2FaOpened] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const twoFaCongitoUser = useRef();

  const resetFailedLoginAttempts = () => {
    localStorage.removeItem("loginAttempts");
    localStorage.removeItem("lastAttemptTimestamp");
  };

  const handleErrorCredentials = async (data: LoginFormData) => {
    const currentTime = Date.now();
    const attempts = toNumber(localStorage.getItem("loginAttempts")) + 1;
    const lastAttemptTimestamp = toNumber(
      localStorage.getItem("lastAttemptTimestamp")
    );

    // Check if an hour has passed since the last attempt
    if (currentTime - lastAttemptTimestamp > MAX_LOGIN_ATTEMPTS_TIME) {
      localStorage.setItem("loginAttempts", "1");
      localStorage.setItem("lastAttemptTimestamp", currentTime.toString());
      toastifyError(
        t("auth:errors:INCORRECT_CREDENTIALS", {
          tries: MAX_LOGIN_ATTEMPTS - 1,
        })
      );
    } else if (attempts >= MAX_LOGIN_ATTEMPTS) {
      // reached max attempts in an hour
      localStorage.setItem("userBlocked", "true");
      try {
        await dispatch(forceResetUserPassword(data.username)).unwrap();
        localStorage.setItem("loginAttempts", attempts.toString());
        toastifyError(
          t("auth:errors:REACHED_MAX_ATTEMPTS", {
            attempts: MAX_LOGIN_ATTEMPTS,
          })
        );
      } catch (error: any) {
        if (error.data.message === "USER_INACTIVE") {
          toastifyError(t("auth:errors:inactiveUser"));
        } else {
          toastifyError(t("auth:errors:REACHED_MAX_ATTEMPTS_EMAIL_FAILED"));
        }
      }
    } else {
      // less than max attempts
      localStorage.setItem("lastAttemptTimestamp", currentTime.toString());
      localStorage.setItem("loginAttempts", attempts.toString());
      toastifyError(
        t("auth:errors:INCORRECT_CREDENTIALS", {
          tries: MAX_LOGIN_ATTEMPTS - attempts,
        })
      );
    }
  };

  const handleErrorLogin = (
    errorMessage: string | undefined,
    data: LoginFormData
  ) => {
    switch (errorMessage) {
      case "User does not exist.":
        toastifyError(t("auth:errors:USER_DOES_NOT_EXIST"));
        break;
      case "Incorrect username or password.":
        handleErrorCredentials(data);
        break;
      case "Temporary password has expired and must be reset by an administrator.":
        toastifyError(t("auth:errors:TEMPORARY_PASS_EXPIRED"));
        break;
      case "Password reset required for the user":
        navigate("/resetPassword", {
          state: {
            ...data,
            source: ResetPageStateSource.FORGET_PASSWORD,
          } as ResetPageState,
        });
        break;
      default:
        console.error(errorMessage);
        toastifyError(t("auth:errors:loginError"));
        break;
    }
  };

  const onSubmit = async (data: LoginFormData) => {
    try {
      dispatch(setIsLoginAllowed(true));
      setError('');
      setSubmitting(true);
      const user = await Auth.signIn(data.username, data.password);
      resetFailedLoginAttempts();
      // addLoginLog(user.username, AuditLogStatus.Success);
      if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
        navigate("/resetPassword", {
          state: {
            ...data,
            source: ResetPageStateSource.REQUIRED_NEW_PASSWORD,
          } as ResetPageState,
        });
      } else if (user?.challengeName === "SMS_MFA") {
        setSubmitting(false);
        twoFaCongitoUser.current = user;
        setIs2FaOpened(true);
      }
    } catch (error: any) {
      setError('login:login_failed');
      setSubmitting(false);

      // handleErrorLogin(error?.message, data);
      // addLoginLog(data.username, AuditLogStatus.Failed, error);
    }
  };

  const loginWith2Fa = useCallback(async (code: string) => {
    try {
      setSubmitting(true);
      await Auth.confirmSignIn(twoFaCongitoUser.current, code);
    } catch (err: any) {
      if (err.code === "CodeMismatchException") {
        toastifyError(t("login:invalid_sms_code"));
      }
      toastifyError(err.message);
      setSubmitting(false);
    }
  }, []);

  const cancel2Fa = useCallback(() => {
    setIs2FaOpened(false);
  }, []);

  return { submitting, onSubmit, loginWith2Fa, cancel2Fa, error, is2FaOpened };
};

export default useLogin;
