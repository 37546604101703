// Base styles
import * as tooltipStatic from "assets/components-static-styles/components/tooltip";
import colors from "assets/theme/base/colors";

// Helper functions
import { merge } from "lodash";
import pxToRem from "../functions/pxToRem";

const {  info,text } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    tooltip: {
      backgroundColor: info.main,
      color: text.main,
      fontSize: pxToRem(12)
    },

    arrow: {
      color:  info.main,
    },
  },
};

const tooltip: Types = merge(themeStyles, tooltipStatic.default);

export default tooltip;
