import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import type { ApiParamsType, ApiResponseType } from "../../types/api";
import serialize from "./serialize";
import ApiMap from "./map";
import ApiException from "../../types/api";
import { logout } from "utils/auth.utils";
import { toastifyError } from "utils/toastify-message";
import { t } from "i18next";
import { Auth } from "aws-amplify";
import { delay } from "lodash";
import { dayjs } from 'services/dayjs';

let handledInactiveUser = false;

class HTTP {
  organizationId = "";
  globalError = "";
  timeout = 0;

  instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}/`,
    // withCredentials: true,
  });

  public initHttpService(orgId: string) {
    this.organizationId = orgId;
  }

  public setGlobalError(error: string) {
    this.globalError = error;
  }

  public setTimeout(timeout: number) {
    this.timeout = timeout;
  }

  public async getAuthData() {
    try {
      const currentSession = await Auth.currentSession();
      const accessToken = currentSession?.getAccessToken()?.getJwtToken();
      const idToken = currentSession?.getIdToken()?.getJwtToken();
      return { accessToken, idToken };
    } catch (e: any) {
      console.log({ e });
      return {};
    }
  }

  private getAuthHeader(token: string, idToken: string): { Authorization: string; Idtoken: string } | {} {
    if (token) {
      return { Authorization: `${token}`, Idtoken: idToken };
    }
    return {};
  }

  private async getRequestConfig({
    key,
    data,
    params,
    queryParams,
    otherApiConfig,
    extraHeader,
  }: ApiParamsType): Promise<AxiosRequestConfig<any>> {
    const { url, method, responseType } = otherApiConfig || ApiMap[key] || {};
    const { accessToken, idToken } = await this.getAuthData();

    return {
      headers: {
        'Time-Zone': dayjs.tz.guess(),
        ...this.getAuthHeader(accessToken, idToken),
        ...(extraHeader || {}),
      },
      url: serialize(url || "", params),
      timeout: this.timeout,
      method,
      params: queryParams,
      data,
      responseType: responseType,
    };
  }

  public async request<TResponse>(
    apiParams: ApiParamsType
  ): Promise<TResponse> {
    try {
      if (ApiMap[apiParams.key]?.mock) {
        const res: any = {
          url: "",
          timeout: this.timeout,
          method: "get",
          params: {},
          data: ApiMap[apiParams.key].mock(),
        };
        return res.data;
      }
      const config = await this.getRequestConfig(apiParams);
      const response = await this.instance<TResponse>(config);
      return response.data;
    } catch (error: any) {
      const originalConfig = error.config;

      switch (error.response?.status) {
        case 403:
          if (error.response?.data?.message === "USER_INACTIVE") {
            if (handledInactiveUser === false) {
              handledInactiveUser = true;
              toastifyError(t("auth:errors:inactiveUser"));
              // delay to see toaster error
              delay(this.logoutUser, 3000);
            }
          } else if (
            error.response?.data?.message === "USER_HAS_NO_PERMISSION"
          ) {
            toastifyError(t("auth:errors:userHasNoPermissions"));
          } else {
            this.logoutUser();
          }
          break;
        // aws refresh itself automatically when it necessary if you use Auth.currentSession()
        // case 401:
        //   // Access token expired - send request to refreshToken api
        //   if (originalConfig.url !== "/auth/login" && !originalConfig._retry) {
        //     originalConfig._retry = true;
        //     // return (await this.refreshToken(originalConfig)).data;
        //   }
        //   break;

        case 502: {
          toastifyError(
            t("toastMessages:error:failConnectApi", {
              errorMessage: error.response?.data?.message,
            })
          );
          throw this.toApiException(error);
        }

        default: {
          throw this.toApiException(error);
        }
      }
    }
  }

  private toApiException(error: any): ApiException {
    return {
      message: error.message,
      statusCode: error.status || error.response?.status,
      status: false,
      data: error.response?.data || {
        message: error.message,
        statusCode: error.status || error.response?.status,
      },
      name: error.name,
    };
  }

  // private refreshTokenPendingPromise:
  //   | Promise<AxiosResponse<AuthData, any>>
  //   | undefined;

  // aws refresh itself automatically when it necessary if you use Auth.currentSession()
  // refresh token is used both for request response with 401 and also to refresh token when session timeout updated in settings page
  // public async refreshToken(
  //   originalConfig?: any
  // ): Promise<AxiosResponse<any, any>> {
  //   try {
  //     // if multiple calls to refresh token - do only one - otherwise one of them will be sent with inactive refreshToken (fix INC-237)
  //     if (!this.refreshTokenPendingPromise) {
  //       // call refreshToken api with refreshToken in header
  //       const refreshConfig = this.getRequestConfig({
  //         key: "refreshToken",
  //         extraHeader: this.getAuthHeader(getStoredRefreshToken()),
  //       });
  //       this.refreshTokenPendingPromise =
  //         this.instance<AuthData>(refreshConfig);
  //     }
  //     const res = await this.refreshTokenPendingPromise;
  //     this.refreshTokenPendingPromise = undefined;
  //     // store the updated data
  //     storeAuthData(res.data);

  //     if (originalConfig) {
  //       // recall the original request with the updated access token
  //       return this.instance({
  //         ...originalConfig,
  //         headers: {
  //           ...originalConfig.headers,
  //           ...this.getAuthHeader(getStoredAccessToken()),
  //         },
  //       });
  //     }
  //   } catch (error: any) {
  //     // the nest passport refresh guard - returns 401 when refresh token expired (and need to re-login)
  //     if (error.response?.status === 401 || error.response?.status === 403) {
  //       this.logoutUser();
  //     }
  //     return Promise.reject(error);
  //   }
  // }

  private async logoutUser(): Promise<void> {
    try {
      // call logout api
      const config = await this.getRequestConfig({ key: "logout" });
      await this.instance<void>(config);
      logout();
    } catch (error) {
      logout();
    }
  }
}

const http = new HTTP();
export default http;
