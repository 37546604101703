// Base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";

//  Helper Function
import rgba from "assets/theme/functions/rgba";

const { black } = colors;
const { borderWidth, borderRadius } = borders;
const { md } = boxShadows;

// types
// types
type Types = any;

const card: Types = {
  styleOverrides: {
    root: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      minWidth: 0,
      wordWrap: "break-word",
      backgroundClip: "border-box",
      borderRadius: borderRadius.xl,
      boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.25)",
      overflow: "visible",
    },
  },
};

export default card;
