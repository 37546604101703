import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { t } from "i18next";
import { User } from "interfaces/user";
import http from "services/http/http";
import { RootState } from "store/store";
import ApiException from "types/api";
import { UserConfig } from "types/user";
import { toastifyError } from "utils/toastify-message";

interface UserState {
  loading: boolean;
  user: User;
}


const BASE = "users";


const initialState: UserState = {
  loading: false,
  user: {} as User,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAuthDataFromStorage: (state) => {
      return { ...state };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserConfig.fulfilled, (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    });
  },
});

export const fetchUserConfig = createAsyncThunk(
  `${BASE}/fetchUserConfig`,
  async (_, { rejectWithValue }) => {
    return await http
      .request<User>({
        key: "getUserConfig",
      })
      .then((res) => res)
      .catch((err: ApiException) => rejectWithValue(err));
  }
);


export const getUserConfig = (state: RootState) => state.user.user;

export default userSlice;
