import { logout } from "utils/auth.utils";


const useBottomMenu = () => {

  const handleLogout = () => {
    logout();
  };

  return { handleLogout };
};

export default useBottomMenu;
