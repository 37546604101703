// Base styles
import * as checkboxStatic from "assets/components-static-styles/components/form/checkbox";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

// Helper functions
import { merge } from "lodash";
import linearGradient from "assets/theme/functions/linearGradient";

const { borderWidth, borderColor } = borders;
const { transparent, gradients, primary } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    root: {
      "& .MuiSvgIcon-root": {
        color: transparent.main,
        border: `1px solid ${primary.text}`,
      },

      "&:hover": {
        backgroundColor: transparent.main,
      },

      "&.Mui-focusVisible": {
        border: `1px solid ${primary.text} !important`,
      },
    },

    colorPrimary: {
      color: borderColor,

      "&.Mui-checked": {
        color: primary.contrastText,

        "& .MuiSvgIcon-root": {
          backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"), ${linearGradient(
            gradients.primary.state,
            gradients.primary.main,
            90
          )}`,
          borderColor: gradients.primary.main,
        },
      },
    },

    colorSecondary: {
      color: borderColor,

      "& .MuiSvgIcon-root": {
        color: primary.contrastText,
        "&.Mui-checked": {
          backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"), ${linearGradient(
            gradients.primary.state,
            gradients.primary.main,
            90
          )}`,
          borderColor: gradients.primary.main,
        },
      },
    },
  },
};
const checkbox: Types = merge(themeStyles, checkboxStatic.default);

export default checkbox;
