import { ApiMapType, Methods } from "types/api.d";

const ApiMap: ApiMapType = {
  updateUserConfig: {
    url: "users/config/",
    method: Methods.POST,
    mock: () => ({
      username: "username",
      darkMode: Math.random() > 0.5,
    }),
  },
  updateSelectedLangauge: {
    url: "config/language/",
    method: Methods.POST,
    mock: () => ({
      selectedLanguage: ["en", "he"][
        Math.floor(Math.random() * ["en", "he"].length)
      ],
    }),
  },

  forceResetPassword: {
    url: "/auth/forgot/password/",
    method: Methods.POST,
  },

  getLoggedUser: {
    url: "/data/",
    method: Methods.GET,
  },
  updateUserData: {
    url: "/data/",
    method: Methods.POST,
  },
  
  getNotifications: {
    url: "/p/notifications/",
    method: Methods.GET,
  },
  viewNotifications: {
    url: "/p/notifications/view/",
    method: Methods.PUT,
  },

  getDashboard: {
    url: "/p/dashboard/",
    method: Methods.GET,
  }

};

export default ApiMap;
