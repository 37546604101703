// Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";

// types
type Types = any;

const tab: Types = {
  styleOverrides: {
    root: {
      // marginLeft: "28px",
      // display: "flex",
      // alignItems: "center",
      // flexDirection: "row",
      // textAlign: "center",
      // maxWidth: "unset !important",
      // minWidth: "unset !important",
      // minHeight: "unset !important",
      textTransform: "none",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "22px",
      letterSpacing: "0.7px",
      textAlign: "left",
      opacity: "1 !important",
      // lineHeight: "inherit",
      // padding: "0px 0px 5px 0px",
      // borderRadius: borderRadius.lg,
      // opacity: "1 !important",
      // fontWeight: "bold",

      // "& .material-icons, .material-icons-round": {
      //   marginBottom: "0 !important",
      //   marginRight: pxToRem(6),
      // },

      // "& svg": {
      //   marginBottom: "0 !important",
      //   marginRight: pxToRem(6),
      // },
    },

    labelIcon: {
      paddingTop: pxToRem(4),
    },
  },
};

export default tab;
