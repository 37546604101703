// Base styles
import colors from "assets/theme/base/colors";

// Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";
import boxShadow from "assets/theme/functions/boxShadow";
import rgba from "assets/theme/functions/rgba";
import linearGradient from "assets/theme/functions/linearGradient";

const { white, secondary, gradients } = colors;

// types
type Types = any;

const stepIcon: Types = {
  styleOverrides: {
    root: {
      background: rgba(white.main, 1),
      fill: rgba(white.main, 0.5),
      color: rgba(white.main, 0.5),
      // boxShadow: boxShadow([0, 0], [0, 2], white.main, 0.5),
      stroke: rgba(white.main, 0),
      strokeWidth: pxToRem(10),
      width: pxToRem(16),
      height: pxToRem(16),
      borderRadius: "50%",
      zIndex: 99,
      transition: "all 200ms linear",

      "&.Mui-active": {
        background: linearGradient(
          gradients.primary.main,
          gradients.primary.state,
          90
        ),
        fill: "url(#paint0_linear_2033_73606)",
        stroke: "url(#paint0_linear_2033_73606)",
        borderImage: linearGradient(
          gradients.primary.main,
          gradients.primary.state,
          90
        ),
        borderImageSlice: 1,
        // boxShadow: boxShadow([0, 0], [0, 2], white.main, 1),
      },

      "&.Mui-completed": {
        background: linearGradient(
          gradients.primary.main,
          gradients.primary.state,
          90
        ),
        fill: "url(#paint0_linear_2033_73606)",
        stroke: "url(#paint0_linear_2033_73606)",
        borderImage: linearGradient(
          gradients.primary.main,
          gradients.primary.state,
          90
        ),
        borderImageSlice: 1,
        // boxShadow: boxShadow([0, 0], [0, 2], white.main, 1),
      },
    },
  },
};

export default stepIcon;
