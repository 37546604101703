import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//  examples components
import SidenavCollapse from "components/Sidenav/SidenavCollapse";
import SidenavList from "components/Sidenav/SidenavList";
import SidenavItem from "components/Sidenav/SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "components/Sidenav/SidenavRoot";
import sidenavLogoLabel from "components/Sidenav/styles/sidenav";

// icons
import LogoutIcon from "@mui/icons-material/Logout";

//  context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import { Route } from "interfaces/infra";

import { globalColors } from "assets/colors-global";
import pxToRem from "assets/theme/functions/pxToRem";
import { Theme, styled, useTheme } from "@mui/material";
import { isBackofficeUser } from "utils/env.utils";
import useBottomMenu from "./sidenav.hook";
import { useAppSelector } from "store/hooks";
import { selectUserRole } from "store/slices/users-data";
import { getIsMobile } from "store/slices/general";
import linearGradient from "assets/theme/functions/linearGradient";
import colors from "assets/theme/base/colors";

// Declaring props types for Sidenav
interface Props {
  color?:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "dark";
  brand?: string;
  routes: Route[];
  [key: string]: any;
}

function Sidenav({ color, brand, routes, ...rest }: Props): React.ReactElement {
  const userRole = useAppSelector(selectUserRole);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [filteredRoutes, setFilteredRoutes] = useState<Route[]>([]);
  const [openCollapse, setOpenCollapse] = useState<boolean | string>(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState<
    boolean | string
  >(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];
  const { handleLogout } = useBottomMenu();
  const isMobile = useAppSelector(getIsMobile);

  let textColor:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "dark"
    | "white"
    | "inherit"
    | "text"
    | "light" = "white";

    textColor = "dark";

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, []);

  useEffect(() => {
    // setFilteredRoutes(filterUnAuthurizedRoutes(routes, userRole));
    setFilteredRoutes(routes);
  }, [userRole, routes]);

  useEffect(() => {
    setFilteredRoutes(filterRoutesByWindowWidth(routes, windowWidth));
  }, [windowWidth, routes]);

  const filterRoutesByWindowWidth = (routes: Route[], windowWidth: number) => {
    return routes.filter((route) => {
      if (route.minWindowSize) {
        return windowWidth >= route.minWindowSize;
      }
      return true;
    });
  };

  const handleMiniSidenav = () => {
    setMiniSidenav(dispatch, window.innerWidth < 1200);
    setTransparentSidenav(
      dispatch,
      window.innerWidth < 1200 ? false : transparentSidenav
    );
    setWhiteSidenav(dispatch, window.innerWidth < 1200 ? true : whiteSidenav);
  };

  const handleWindowsResize = () => {
    handleMiniSidenav();
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // A function that sets the mini state of the sidenav.

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleWindowsResize);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse: any) => {
    const template = collapse.map(({ name, route, key, href }: any) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <StyledNavLink
          ownerState={{ color }}
          to={route}
          key={key}
          style={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} active={route === pathname} nested />
        </StyledNavLink>
      )
    );

    return template;
  };
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses: any) =>
    collapses.map(({ name, collapse, route, navigateTo, href, key }: any) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            color={color}
            name={name}
            active={key === itemParentName ? "isParent" : false}
            open={openNestedCollapse === key}
            onClick={({ currentTarget }: any) =>
              openNestedCollapse === key &&
              currentTarget.classList.contains("MuiListItem-root")
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem color={color} name={name} active={key === itemName} />
          </Link>
        ) : (
          <StyledNavLink
            ownerState={{ color }}
            to={navigateTo || route}
            key={key}
            style={{ textDecoration: "none" }}
          >
            <SidenavItem color={color} name={name} active={key === itemName} />
          </StyledNavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  const renderLogout = () => {
    return (
      <SidenavCollapse
        onClick={handleLogout}
        name={"logout"}
        icon={<LogoutIcon />}
        noCollapse={true}
      />
    );
  };

  const renderBottomRoutes = (_routes: Route[]) => {
    return renderRoutes(_routes.filter((route) => route.type === "bottom"));
  };

  const renderTopRoutes = (_routes: Route[]) => {
    return renderRoutes(_routes.filter((route) => route.type !== "bottom"));
  };

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = (_routes: any) =>
    _routes.map(
      ({
        type,
        name,
        icon,
        activeIcon,
        title,
        collapse,
        noCollapse,
        navigateTo,
        key,
        href,
        route,
      }: any) => {
        let returnValue;

        if (type === "collapse") {
          if (href) {
            returnValue = (
              <Link
                href={href}
                key={key}
                target="_blank"
                rel="noreferrer"
                sx={{ textDecoration: "none" }}
              >
                <SidenavCollapse
                  name={name}
                  icon={key === collapseName ? (activeIcon || icon) : icon}
                  active={key === collapseName}
                  noCollapse={noCollapse}
                />
              </Link>
            );
          } else if (noCollapse && route) {
            returnValue = (
              <StyledNavLink to={route} key={key} ownerState={{ color }}>
                <SidenavCollapse
                  name={name}
                  icon={key === collapseName ? (activeIcon || icon) : icon}
                  noCollapse={noCollapse}
                  active={key === collapseName}
                >
                  {collapse ? renderCollapse(collapse) : null}
                </SidenavCollapse>
              </StyledNavLink>
            );
          } else {
            returnValue = (
              <SidenavCollapse
                key={key}
                name={name}
                icon={icon}
                active={key === collapseName}
                open={openCollapse === key}
                onClick={() =>
                  openCollapse === key
                    ? setOpenCollapse(false)
                    : setOpenCollapse(key)
                }
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            );
          }
        } else if (type === "title") {
          returnValue = (
            <MDTypography
              key={key}
              color={textColor}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {title}
            </MDTypography>
          );
        } else if (type === "divider") {
          returnValue = <Divider key={key} light={false} />;
        } else if (type === "bottom") {
          if (noCollapse && route) {
            returnValue = (
              <StyledNavLink
                className={"NavLink-bottom"}
                to={navigateTo || route}
                key={key}
                ownerState={{ color }}
              >
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  noCollapse={noCollapse}
                  active={key === collapseName}
                />
              </StyledNavLink>
            );
          }
        }

        return returnValue;
      }
    );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={2} pb={2} px={2} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="white">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
      {!isMobile && (
          <>
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={{ xs: 3, xl: 0 }}
            mb={1}
          >
            {brand && (
              <MDBox
                p="1px"
                bgColor="white"
                width={miniSidenav ? "3.5rem" : pxToRem(148)}
                // height={miniSidenav ? "3.5rem" : pxToRem(56)}

                component="img"
                src={brand}
                alt="Brand"
              >

              </MDBox>
            )}
          </MDBox>
          <Divider
            sx={{
              margin: "0 1rem 1rem",
              background: globalColors.sidebar.text,
              height: "1px"
            }}          
          />
        </>)
      }
      </MDBox>
      <List>{renderTopRoutes(filteredRoutes)}</List>
      <List sx={{ marginTop: "auto", marginBottom: pxToRem(16) }}>
        {renderBottomRoutes(filteredRoutes)}
        {renderLogout()}
      </List>
    </SidenavRoot>
  );
}

const StyledNavLink = styled(NavLink)(({ ownerState }: { ownerState: any }) => {
  const { color } = ownerState;
  const theme: any = useTheme();
  return {
    "&.active .sidenav-item": {
      background: 'transparent',//theme.palette.gradients[color].main
    },
    "&.active .sidenav-item span": {
      color: globalColors.sidebar.activeText
    },
    ".sidenav-item:hover span": {
      color: globalColors.sidebar.activeText
    },
    "&.NavLink-bottom:not(.active) .MuiTypography-root, &.NavLink-bottom:not(.active) svg":
      {
        // opacity: 0.7,
      },
  };
});
// Declaring default props for Sidenav
Sidenav.defaultProps = {
  color: "dark",
  brand: "",
};

export default Sidenav;
