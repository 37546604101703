// Base styles
import * as staticRoot from "assets/components-static-styles/components/button/root";

import colors from "assets/theme/base/colors";
import { merge } from "lodash";
const { transparent, light, info, secondary } = colors;

const themeStyles = {
  root: {
    padding: "4px 10px",
  },
};

const mergedStyles = merge(themeStyles, staticRoot.default);

const root = {
  ...mergedStyles,
};

export default root;
