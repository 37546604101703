// Base styles
import * as tabStatic from "assets/components-static-styles/components/tabs/tab";
import colors from "assets/theme/base/colors";

// Helper functions
import { merge } from "lodash";

const { text } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    root: {
      color: `${text.main}`,
      minWidth: "50px !important",
      width: "50px !important",
      // color: `${dark.main} !important`,
    },
  },
};

const tab: Types = merge(themeStyles, tabStatic.default);

export default tab;
