export enum OrderFields {
  ID = "id",
  STATUS = "status",
  SUPPLIER_ID = "supplier_id",
  SUPPLIER_NAME = "supplier_name",
  DISTRIBUTOR_ID = "distributor_id",
  DISTRIBUTOR_NAME = "distributor_name",
  ORDER_TYPE = "order_type",
  ORDERED_AT_DATE = "ordered_at_date",
  SUPPLIED_AT_DATE = "supplied_at_date",
  LINES_QUANTITY = "lines_quantity",
  QUANTITY = "quantity",
}
