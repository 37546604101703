import { ActionReducerMapBuilder, EntityState } from "@reduxjs/toolkit";
import { AsyncThunk, EntityAdapter } from "@reduxjs/toolkit";
import { FetchStatusType } from "types/store";

export interface BaseListState {
  loading: FetchStatusType;
}

export const addBaseListCases = <T>(
  builder: ActionReducerMapBuilder<
    EntityState<T> & {
      loading: FetchStatusType;
    }
  >,
  action: AsyncThunk<any, any, any>,
  entityAdapter: EntityAdapter<T>
) => {
  return builder
    .addCase(action.pending, (state, action) => {
      state.loading = "pending";
    })
    .addCase(action.fulfilled, (state, action) => {
      entityAdapter.setAll(state as EntityState<T>, action.payload.data);
      state.loading = "succeeded";
    })
    .addCase(action.rejected, (state, action) => {
      state.loading = "failed";
    });
};

export const deleteBaseCases = <T>(
  builder: ActionReducerMapBuilder<
    EntityState<T> & {
      loading: FetchStatusType;
    }
  >,
  action: AsyncThunk<any, any, any>,
  entityAdapter: EntityAdapter<T>
) => {
  return builder
    .addCase(action.pending, (state, action) => {
      state.loading = "pending";
    })
    .addCase(action.fulfilled, (state, action) => {
      console.log({ action });
      entityAdapter.removeOne(state as EntityState<T>, action.payload);
      state.loading = "succeeded";
    })
    .addCase(action.rejected, (state, action) => {
      state.loading = "failed";
    });
};
