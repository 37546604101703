// Base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import linearGradient from "assets/theme/functions/linearGradient";

const { borderWidth } = borders;
const { gradients, white } = colors;

// types
type Types = any;

const stepConnector: Types = {
  styleOverrides: {
    root: {
      transition: "all 200ms linear",

      "&.Mui-active": {
        ".MuiStepConnector-line": {
          borderWidth: `${borderWidth[2]} !important`,
          borderImage: linearGradient(
            gradients.primary.main,
            gradients.primary.state,
            90
          ),
          borderImageSlice: 1,
          opacity: 1,
        },
      },

      "&.Mui-completed": {
        ".MuiStepConnector-line": {
          borderWidth: `${borderWidth[2]} !important`,
          borderImage: linearGradient(
            gradients.primary.main,
            gradients.primary.state,
            90
          ),
          borderImageSlice: 1,
          opacity: 1,
        },
      },
    },

    alternativeLabel: {
      top: "14%",
      left: "-50%",
      right: "50%",
    },

    line: {
      borderWidth: `${borderWidth[2]} !important`,
      borderColor: white.main,
      opacity: 1,
    },
  },
};

export default stepConnector;
