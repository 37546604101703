import {
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import http from "services/http/http";
import ApiException from "types/api";

const BASE = "dashboard";

interface DashboardState {
  url: string;
}

const initialState: DashboardState = {
  url: "",
};

const dashboardSlice = createSlice({
  name: BASE,
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDashboard.pending, (state) => {
        state.url = '';
      });
    builder.addCase(fetchDashboard.fulfilled, (state, action) => {
      state.url = action.payload.url;
    });
  },
});

export const fetchDashboard = createAsyncThunk(
  `${BASE}/fetchDashboard`,
  async (_, { rejectWithValue }) => {
    return await http
      .request<DashboardState>({
        key: "getDashboard",
      })
      .catch((err: ApiException) => rejectWithValue(err));
  }
);

export default dashboardSlice;
