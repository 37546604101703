// @mui material components
import { Slider } from "@mui/material";
import { Theme, styled } from "@mui/material/styles";

export default styled(Slider)(
  ({ theme }: { theme?: Theme }) => {

    return {
      '& .MuiSlider-valueLabelOpen': {
        background: '#202658',
        borderRadius: '6px',
        color: 'white',
        fontSize: '14px',
        padding: '0rem 0.3rem',
      },

      '& .MuiSlider-thumb': {
        background: 'linear-gradient(180deg, #FCCA97 0%, #FF8265 51.56%, #FF015E 100%)',
      }

    };
  }
);
