import { useState, useEffect, ReactNode, useCallback } from "react";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import logo from "assets/images/logos/Logo.webp";
import { ReactComponent as MenuIcon } from "assets/Icons/menu.svg";
import LogoutIcon from "@mui/icons-material/Logout";
import typography from "assets/theme/base/typography";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
} from "components/Navbars/DashboardNavbar/styles";

//  context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
} from "context";
import { Badge, ClickAwayListener, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";

import { useLocation, useNavigate } from "react-router-dom";
import { globalColors } from "assets/colors-global";
import { useAppDispatch, useAppSelector } from "store/hooks";
import MDTypography from "components/MDTypography";
import pxToRem from "assets/theme/functions/pxToRem";
import MDButton from "components/MDButton";
import { getIsMobile } from "store/slices/general";
import { logout } from "utils/auth.utils";
import { ResetPageStateSource } from "types/auth";
import { PAGE_HEADER_HEIGHT_MOBILE } from "layouts/Main/styles";
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { NotificationGroup } from "interfaces/notification";
import { viewNotification } from "store/slices/notifications";
import { NotificationDropdown } from "./NotificationDropdown";

const ICON_SIZE = 80;

const selectStyles = {
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent !important",
  },
  ".Mui-focused": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important",
    },
  },
};

// Declaring prop types for DashboardNavbar
interface Props {
  absolute?: boolean;
  light?: boolean;
  isMini?: boolean;
  pageTitle?: string;
  pageIcon?: any;
  extraRoutes?: { text: string; navigate?: () => void }[];
  navigate?: () => void;
  showSearch?: boolean;
  onSearchChange?: (value: string) => void;
  breadcrumbIcon?: ReactNode;
  showBreadcrumbs?: boolean;
}

const useStyles = makeStyles({
  button: {
    padding: "8px 16px !important",
    height: "36px !important",
    minHeight: "36px !important",
  },
});
function DashboardNavbar({
  absolute,
  light,
  isMini,
  pageTitle,
  navigate,
  onSearchChange,
}: Props): React.ReactElement {
  const { size } = typography;
  const [navbarType, setNavbarType] = useState<
    "fixed" | "absolute" | "relative" | "static" | "sticky"
  >("static");
  const [controller, dispatch] = useMaterialUIController();
  const navigateTo = useNavigate();
  const isMobile = useAppSelector(getIsMobile);
  const [menuDropdownOpened, setMenuDropdownOpened] = useState(false);
  const [notificationsOpened, setNotificationsOpened] = useState(false);
  const { t } = useTranslation();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode, direction } =
    controller;
  const appDispatch = useAppDispatch();
  const user = useAppSelector(state => state.users.loggedUser);
  const notifications = useAppSelector(state => state.notifications.notifications);
  const notificationCount = useAppSelector(state => state.notifications.count);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  const handleLogout = useCallback((e: any) => {
    e.stopPropagation();
    logout();
  }, []);

  const viewNotificationGroup = useCallback((data: NotificationGroup) => {
    appDispatch(viewNotification({ data, navigate: navigateTo }));
  }, []);

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }: {
    palette: any;
    functions: any;
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const route = useLocation()
    .pathname.replace(/%20/g, " ")
    .split("?")[0]
    .split("/")
    .slice(1);

  const renderAccountDropdown = () => (
    <ClickAwayListener onClickAway={() => setMenuDropdownOpened(false)}>
      <MDBox position="absolute" textAlign="left" top="55px" right="0" width="300px" zIndex="2">
        <MDBox position="absolute" top="-12px" right="10px" sx={{
            width: 0,
            height: 0, 
            borderLeft: '15px solid transparent',
            borderRight: '15px solid transparent',
            borderBottom: `15px solid ${globalColors.primary1.main}`,
        }} />
        <MDBox borderRadius="12px" overflow="hidden" bgColor="white" shadow="md">
          <MDBox bgColor={globalColors.primary1.main} p="20px" color="white">
            <Typography fontSize={14} fontWeight="bold">
              {user?.data?.first_name} {user?.data?.last_name}
            </Typography>
          </MDBox>
          <MDBox height="60px" display="flex" alignItems="center">
            <MDButton
              onClick={handleLogout}
              startIcon={<LogoutIcon sx={{ color: globalColors.primary1.main }} />}
              variant="text"
            >
              <MDTypography
                variant="caption"
              >
                {t("navigation:logout")}
              </MDTypography>
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </ClickAwayListener>
  );

  return isMobile ? (
    <AppBar
      position="fixed"
      sx={{ backgroundColor: globalColors.primary3.main }}
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
          div: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          },
          minHeight: pxToRem(PAGE_HEADER_HEIGHT_MOBILE),
          height: pxToRem(PAGE_HEADER_HEIGHT_MOBILE),
        }}
      >
        <div
          style={{
            maxWidth: pxToRem(ICON_SIZE),
            width: pxToRem(ICON_SIZE),
            height: pxToRem(50),
            borderRadius: pxToRem(7.651),
            backgroundColor: globalColors.primary3.main,
          }}
        >
          <img src={logo} alt="" width={ICON_SIZE} height={ICON_SIZE} />
        </div>
        <div
          style={{
            width: `calc(50% - ${pxToRem(ICON_SIZE / 2)})`,
            display: "flex",
            justifyContent: "end",
          }}
        >
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={handleMiniSidenav}
          >
            <MenuIcon />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  ) : (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={navbarContainer}>
        <MDBox display={"flex"} alignItems={"baseline"}>
          <MDBox
            color={light ? "white" : "inherit"}
            mx={1}
            marginLeft={pxToRem(-16)}
          >
            <IconButton
              size="small"
              disableRipple
              color="secondary"
              onClick={handleMiniSidenav}
            >
              <Icon fontSize="medium">
                {miniSidenav ? "menu_open" : "menu"}
              </Icon>
            </IconButton>
          </MDBox>
          <MDBox display={"block"}>
            <MDTypography
              variant="h5"
              color="primary"
              weight="medium"
              fontSize="16px"
            >
              {pageTitle}
            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox>
          {/* <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-haspopup="true"
            onClick={() => setMenuDropdownOpened(true)}
            color="inherit"
          >
            <AccountCircle />
            {menuDropdownOpened ? renderAccountDropdown() : null}
          </IconButton> */}
          
          {/* <IconButton
            size="large"
            color="inherit"
            onClick={() => setNotificationsOpened(true)}
          >
            <Badge badgeContent={notificationCount} color="error">
              <NotificationsIcon />
            </Badge>
            {notificationsOpened 
              ? <NotificationDropdown
                  onClose={() => setNotificationsOpened(false)}
                  notications={notifications}
                  onNotificationClick={viewNotificationGroup}
                  count={notificationCount}
                /> 
              : null
            }
          </IconButton> */}
        </MDBox>
      </Toolbar>
    </AppBar>
  );
}

// Declaring default props for DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

export default DashboardNavbar;
