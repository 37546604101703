import { Theme } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import { isMobileFunc } from "hooks/use-is-mobile.hook";

// Declare the navbar container height.
export const PAGE_HEADER_HEIGHT = 80;
export const PAGE_HEADER_HEIGHT_MOBILE = 105;

export const PageContentMobileStyle = (theme: Theme) => ({
  height: `100%`,
  display: "flex",
  flexDirection: "column",
});

export const PageContentCardStyle = (theme: Theme) => ({
  height: `100%`,
  width: "100%",
  padding: `${pxToRem(0)} 0rem ${pxToRem(10)}`,
  display: "flex",
  flexDirection: "column",
  overflow: 'auto'
});

export const PageContentStyle = (theme?: Theme, headerSize?: number) => ({
  height: `calc(100% - ${pxToRem(
    isMobileFunc()
      ? PAGE_HEADER_HEIGHT_MOBILE
      : (headerSize || PAGE_HEADER_HEIGHT) - 10
  )})`,
  paddingY: isMobileFunc() ? pxToRem(8) : pxToRem(8),
});

export const PageHeaderStyle = (theme?: Theme, headerSize?: number) => ({
  height: pxToRem(
    isMobileFunc()
      ? PAGE_HEADER_HEIGHT_MOBILE
      : headerSize ?? PAGE_HEADER_HEIGHT
  ),
});
