import { Route } from "interfaces/infra";
import { Auth } from "aws-amplify";
import http from "services/http/http";
import { AuditLogStatus } from "enums/audit-log-status.enum";
import { AuditLogActions } from "enums/audit-log-actions.enum";
import { UserDataRole } from "types/user-data";
import { isBackofficeUser } from "./env.utils";
import { addLogoutLog } from "./server-log.utils";

export const logout = async () => {
  await lightLogout();
  !isBackofficeUser && hardLogout();
};

export const lightLogout = async () => {
  const userInfo = await Auth.currentAuthenticatedUser();
  // userInfo?.username && addLogoutLog(userInfo.username);
  await Auth.signOut();
  localStorage.clear();
};

export const hardLogout = () => {
  window.location.href = window.location.origin;
};

export const filterUnAuthurizedRoutes = (
  _routes: Route[],
  userRole: UserDataRole
) => {
  return _routes.filter(
    (route: any) => route.role && route.role.includes(userRole)
  );
};
