import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { t } from "i18next";
import { ALL_OPTION } from "utils/select.utils";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Option = { label: string; value: number | string };
type Props = {
  options: Option[];
  value: Option[];
  defaultValue?: Option[];
  name?: string;
  onChange: (val: (string | number)[], name: string) => void;
  placeholder?: string;
  valueToString: (value: any[]) => string;
  label?: string;
  variant?: "filled" | "standard" | "outlined";
  disabled?: boolean; 
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "40px !important",
    fontWeight: "bold !important",
    fontSize: "14px !important",
    color: `${(theme.palette.primary as any).text} !important`,
    padding: '10px 6px',
  },
  notchedOutline: {
    borderColor: `${(theme.palette.primary as any).text} !important`,
  },
  option: {
    padding: "0 !important",
    fontSize: "14px !important",
    color: `${(theme.palette.primary as any).text} !important`,
  },
  popper: { width: "200px !important", left: "-46px !important" },
  popupIndicator: {
    color: `${(theme.palette.primary as any).text} !important`,
  },
}));

const getValuesFromOptions = (localVal: (Option | string)[]): string[] =>
  localVal.map((val) => (val as Option)?.value ?? val) as string[];

export default function MultiSelect({
  options,
  value,
  defaultValue,
  onChange,
  name,
  placeholder,
  valueToString,
  label,
  variant = 'standard',
  disabled,
}: Props) {
  const classes = useStyles();
  const [localVal, setLocalVal] = React.useState<Option[]>(defaultValue || []);

  const selectOptions = React.useMemo(() => {
    if (!disabled) return options;
    // if disabled show selected items first
    const selectedVal = (value || [])?.map(el => el?.value ?? el) as string[];
    return [...options].sort((a,b) => selectedVal.includes(a.value as string) ? -1 : 1);
  }, [options, value, disabled]);

  return (
    <Autocomplete
      noOptionsText={t("general:no_result_msg")}
      multiple
      options={selectOptions}
      isOptionEqualToValue={(opt, val) => {
        return opt.value === (val.value || val);
      }}
      key={JSON.stringify(selectOptions)}
      openOnFocus
      onChange={(event, newValue, reason, details) => {
        if (disabled) return;
        if (reason === "removeOption" && details.option.value === ALL_OPTION.value) {
          value && onChange([], name);
          setLocalVal([]);
        } else if (reason === "selectOption" && details.option.value === ALL_OPTION.value) {
          value && onChange(getValuesFromOptions(selectOptions), name);
          setLocalVal(selectOptions);
        } else {
          const val = (newValue as Option[]).filter(
            (op: Option) => op.value !== ALL_OPTION.value
          );
          value && onChange(getValuesFromOptions(val), name);
          setLocalVal(val);
        }
      }}
      onBlur={() => {
        if (disabled) return;
        onChange(getValuesFromOptions(localVal), name);
      }}
      disableCloseOnSelect
      // placeholder={placeholder}
      limitTags={1}
      disableClearable
      size="small"
      value={value || localVal}
      classes={{
        option: classes.option,
        popper: classes.popper,
        popupIndicator: classes.popupIndicator,
      }}
      getOptionLabel={(option) =>
        typeof option !== "string" ? option?.label : option
      }
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.value}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
            size="small"
          />
          {option?.label}
        </li>
      )}
      popupIcon={<KeyboardArrowDownIcon color="inherit" />}
      renderTags={(value, getTagProps, ownerState) => {
        const allIndex = value.findIndex((v) => v.value === ALL_OPTION.value);
        return ownerState.focused
          ? null
          : allIndex > -1
          ? value[allIndex].label
          : valueToString(value);
      }}
      style={{ minWidth: 110, height: "33px !important" }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant as any}
          label={label}
          placeholder={!localVal.length && !value ? placeholder : ''}
          InputProps={{
            ...params.InputProps,
            classes: {
              root: classes.root,
              notchedOutline: classes.notchedOutline,
            },
        }}
        />
      )}
    />
  );
}
