import {
  createSlice,
} from "@reduxjs/toolkit";
import { isMobileFunc } from "hooks/use-is-mobile.hook";
import { RootState } from "store/store";

const BASE = "general";

// Initial State

const initialState = {
  isMobile: isMobileFunc(),
};

// Slice

const generalSlice = createSlice({
  name: BASE,
  initialState:initialState,
  reducers: {
    setIsMobile: (state, action) => ({
      ...state,
      isMobile: action.payload,
    }),
  }, 
});

export const { setIsMobile } = generalSlice.actions;

export const getIsMobile = (state: RootState) => state.general.isMobile;

export default generalSlice;
