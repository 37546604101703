export interface ResetPageState {
  username?: string;
  password?: string;
  source: ResetPageStateSource;
}

export enum ResetPageStateSource {
  FORGET_PASSWORD = "FORGET_PASSWORD",
  REQUIRED_NEW_PASSWORD = "REQUIRED_NEW_PASSWORD", // first login
  REQUIRED_CHANGE_PASSWORD = "REQUIRED_CHANGE_PASSWORD", // each 3 months
  CHANGE_PASSWORD_MANUALLY = "CHANGE_PASSWORD_MANUALLY",
}
