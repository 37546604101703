// Base styles
import * as staticContained from "assets/components-static-styles/components/button/contained";
import { merge } from "lodash";

import colors from "assets/theme/base/colors";
const { transparent, light, info, secondary } = colors;

const themeStyles = {
  base: {
    padding: "4px 10px",
  },
};

const mergedStyles = merge(themeStyles, staticContained.default);

const contained = {
  ...mergedStyles,
};

export default contained;
