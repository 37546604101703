import {
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import http from "services/http/http";
import ApiException from "types/api";
import { NotificationGroup } from "interfaces/notification";
import { NavigateFunction } from "react-router-dom";
  
const BASE = "notifications";
  
// Initial State
interface NotificationsState {
  loading: boolean;
  notifications: NotificationGroup[];
  count: number;
}

const initialState: NotificationsState = {
  loading: false,
  count: 0,
  notifications: [],
};

// Slice

const notificationsSlice = createSlice({
  name: BASE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      state.count = action.payload.count;
      state.notifications = action.payload.items;
    });
  },
});

export const fetchNotifications = createAsyncThunk(
  `${BASE}/fetchNotifications`,
  async (
    _,
    { rejectWithValue }
  ) => {
    return http
      .request<{ count: number; items: NotificationGroup[] }>({
        key: "getNotifications",
      })
      .catch((err: ApiException) => rejectWithValue(err));
  }
);

export const viewNotification = createAsyncThunk(
  `${BASE}/viewNotification`,
  async (
    { data, navigate, }: { data: NotificationGroup, navigate: NavigateFunction },
    { rejectWithValue, dispatch }
  ) => {
    
    switch(data.type) {
      case 'CAMPAIGN_APPROVED': {
        navigate('/campaigns', { state: { ids: data.items.map(el => el.campaign) } });
        break;
      }
      case 'CAMPAIGN_INACTIVATED': {
        navigate('/campaigns', { state: { ids: data.items.map(el => el.campaign) } });
        break;
      }
      case 'CAMPAIGN_REJECTED': {
        navigate('/campaigns', { state: { ids: data.items.map(el => el.campaign) } });
        break;
      }
      case 'NEW_FEEDBACK_REQUEST': {
        navigate('/leads', { state: { ids: data.items.map(el => el.lead) } });
        break;
      }
      default: {}
    }

    const ids: string[] = data.items.map(el => el.id);
    await http
    .request<{ count: number; items: NotificationGroup[] }>({
      key: "viewNotifications",
      data: {
        items: ids,
      }
    })
    .catch((err: ApiException) => rejectWithValue(err));
    return dispatch(fetchNotifications());
  }
);

export default notificationsSlice;
  