import { Card } from "@mui/material"
import MDBox from "components/MDBox"
import { PageContentCardStyle, PageContentMobileStyle, PageContentStyle } from "layouts/Main/styles"
import { ReactNode } from "react"
import { useAppSelector } from "store/hooks"
import { getIsMobile } from "store/slices/general"

const PageContentContainer = ({ children, component = 'card' }: { children: ReactNode, component?: 'card' | 'div'}) => {
    const isMobile = useAppSelector(getIsMobile)
    return <MDBox sx={PageContentStyle}>{
        isMobile ?
            <MDBox sx={PageContentMobileStyle}>
                {children}
            </MDBox> :
            (component == 'card' ?
                <Card sx={PageContentCardStyle}>
                    {children}
                </Card> :
                <MDBox sx={PageContentCardStyle}>
                    {children}
                </MDBox>)
    }
    </MDBox >
}

export default PageContentContainer
