import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from "@mui/material";
import { globalColors } from "assets/colors-global";
import MDButton from "components/MDButton";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmDialogTypes, DialogOptions } from ".";
import CloseIcon from '@mui/icons-material/Close';
import MDBox from "components/MDBox";

const ConfirmDialog: FC<
  {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
  } & DialogOptions
> = ({ isOpen, onClose, onConfirm, ...options }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={isOpen} onClose={onClose} PaperProps={{
      style:{ overflowX: 'hidden' }
    }} >
      {isOpen ? (
        <>
          <DialogTitle
            style={{ fontSize: 16, paddingBottom: 0, minWidth: 400 }}
          >
            <IconButton
              edge="end"
              color="inherit"
              onClick={onClose}
              aria-label="close"
              sx={{
                position: 'absolute',
                right: 16,
                top: 4,
              }}
            >
              <CloseIcon />
            </IconButton>
            {options.header || ""}
          </DialogTitle>
          <DialogContent
            style={{
              borderBottom: `1px solid ${globalColors.secondary5.main}`,
              paddingBottom: 32,
            }}
          >
            <DialogContentText style={{ fontSize: 14 }}>
              {options.body || ""}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ padding: 1, paddingTop: 2 }}>
            <MDBox>
              {
                options.footer &&
                <MDBox mb={2}>
                  {options.footer}
                </MDBox>
              }
              <MDBox display={'flex'} justifyContent={'end'}>
                {[ConfirmDialogTypes.ALERT, ConfirmDialogTypes.WARNING].includes(
                  options.type
                ) && (
                    <MDButton
                      size="small"
                      variant="outlined"
                      onClick={onClose}
                      color="secondary"
                      autoFocus={options.type === ConfirmDialogTypes.ALERT}
                    >
                      {options.cancelLabel ||
                        t(
                          `confirmDialog:${options.type === ConfirmDialogTypes.ALERT
                            ? "No"
                            : "Cancel"
                          }`
                        )}
                    </MDButton>
                  )}
                <MDButton
                  size="small"
                  color="primary"
                  onClick={onConfirm}
                  autoFocus={options.type !== ConfirmDialogTypes.ALERT}
                >
                  {options.okLabel ||
                    t(
                      `confirmDialog:${options.type === ConfirmDialogTypes.ALERT ? "Yes" : "Ok"
                      }`
                    )}
                </MDButton>
              </MDBox>
            </MDBox>
          </DialogActions>
        </>
      ) : null}
    </Dialog>
  );
};

export default ConfirmDialog;
