/* eslint-disable prefer-destructuring */

// @mui material components
import { Theme } from "@mui/material/styles";
import { globalColors } from "assets/colors-global";

function item(theme: Theme | any, ownerState: any) {
  const { palette, borders, functions, transitions } = theme;
  const { active, color, transparentSidenav, whiteSidenav, darkMode } =
    ownerState;

  const { transparent, white, grey } = palette;
  const { borderRadius } = borders;
  const { rgba } = functions;

  return {
    pl: 1,
    mt: 0.375,
    mb: 0.3,
    width: "100%",
    borderRadius: borderRadius.md,
    cursor: "pointer",
    backgroundColor: 'transparent',
    transition: transitions.create("background-color", {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.shorter,
    }),
      color: active ? globalColors.sidebar.activeText : globalColors.sidebar.text,
    "&:hover, &:focus": {
      backgroundColor: 'transparent',
    },
  };
}

function itemContent(theme: Theme, ownerState: any) {
  const { palette, typography, transitions, functions } = theme;
  const {
    miniSidenav,
    name,
    active,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = ownerState;

  const { white, dark } = palette;
  const { size, fontWeightRegular, fontWeightLight } = typography;
  const { pxToRem } = functions;

  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: `${pxToRem(12)} ${pxToRem(16)}`,
    // marginLeft: pxToRem(18),
    userSelect: "none",
    position: "relative",

    "& span": {
        color: active ? globalColors.sidebar.activeText : globalColors.sidebar.text,
        // ((transparentSidenav && !darkMode) || whiteSidenav) &&
        // (active === "isParent" || !active)
        //   ? dark.main
        //   : white.main,
      fontWeight: `${fontWeightLight} !important`,
      fontSize: `${size.xxs} !important`,
      opacity: miniSidenav ? 0 : 1,
      transition: transitions.create(["opacity", "color"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },
    "&::before": {
      content: `"${name?.length ? name[0] : ""}"`,
        color: active ? globalColors.sidebar.activeText : globalColors.sidebar.text,
      fontWeight: fontWeightRegular,
      display: "flex",
      alignItems: "center",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      left: pxToRem(-15),
      opacity: 1,
      borderRadius: "50%",
      fontSize: size.sm,
    },
  };
}

function itemArrow(theme: Theme, ownerState: any) {
  const { palette, typography, transitions, breakpoints, functions } = theme;
  const {
    noCollapse,
    transparentSidenav,
    whiteSidenav,
    miniSidenav,
    open,
    active,
    darkMode,
  } = ownerState;

  const { white, dark } = palette;
  const { size } = typography;
  const { pxToRem, rgba } = functions;

  return {
    fontSize: `${size.lg} !important`,
    fontWeight: 700,
    marginBottom: pxToRem(-1),
    transform: open ? "rotate(0)" : "rotate(-180deg)",
      color: active ? globalColors.sidebar.activeText : globalColors.sidebar.text,
    transition: transitions.create(["color", "transform", "opacity"], {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("xl")]: {
      display:
        noCollapse || (transparentSidenav && miniSidenav) || miniSidenav
          ? "none !important"
          : "block !important",
    },
  };
}

export { item, itemContent, itemArrow };
