// @mui material components
import Card from "@mui/material/Card";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { globalColors } from "assets/colors-global";

import MDBox from "components/MDBox";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import { useTranslation } from "react-i18next";
import validationSchema, {
  ENGLISH_LETTERS_NUMBERS_SPECIAL_REGEX,
  MIN_MAX_LENGTH_REGEX,
  ONE_LOWERCASE_REGEX,
  ONE_SPECIAL_CHARACTER_REGEX,
  ONE_UPPERCASE_REGEX,
} from "./validation";
import MDFormField from "components/MDFormField";
import MDTypography from "components/MDTypography";
import pxToRem from "assets/theme/functions/pxToRem";
import MDButtonLoading from "components/MDLoadingButton";
import { Icon } from "@mui/material";
import useResetPassword, { ResetFormData } from "./resetPassword.hook";
import { ResetPageStateSource } from "types/auth";
import { useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import { ReactComponent as CloseIcon } from 'assets/Icons/XIcon.svg';
import SignupSuccessfullIcon from 'assets/images/reset-password-successfull.png';
import TwoFactorAuth from "../components/TwoFactorAuth/TwoFactorAuth";

const PasswordChangedModal = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();

  return (
    <MDBox zIndex={2} minWidth={520} bgColor="white" p={2} pb={4} borderRadius="12px">
      <CloseIcon onClick={onClose} />
      <MDBox display="flex" justifyContent="center" mb={2} mt={2}>
        <img src={SignupSuccessfullIcon} width="130" height="205" alt="" />
      </MDBox>
      <MDBox>
        <MDTypography variant="h6" textAlign="center" mb={2} fontSize="20px">
          {t('change_password:success_header')}
        </MDTypography>
        <MDTypography variant="body1" textAlign="center">
          {t('change_password:success_msg')}
        </MDTypography>
      </MDBox>
      <MDBox mt={2} pl={5} pr={5}>
        <MDButton
          fullWidth
          onClick={onClose}
          variant="contained"
          color="primary"
        >
          {t('change_password:go_to_login')}
        </MDButton>
      </MDBox>
    </MDBox>
  );
}

const ResetPassword = (): React.ReactElement => {
  const { t } = useTranslation();
  const { onSubmit, submitting, source, passwordChanged, loginWith2Fa, is2FaOpened, close2Fa } = useResetPassword();
  const navigate = useNavigate();

  const requiredOldPassword = useMemo(
    () =>
      source === ResetPageStateSource.CHANGE_PASSWORD_MANUALLY ||
      source === ResetPageStateSource.REQUIRED_CHANGE_PASSWORD,
    [source]
  );

  const form = useForm<ResetFormData>({
    resolver: yupResolver(validationSchema(requiredOldPassword)),
    mode: "onBlur",
  });

  const renderValidationRow = (text: string, ...regexps: RegExp[]): React.ReactNode => {
      const isDirty = form.getFieldState("newPassword").isDirty;
      const passedValidation = isDirty && regexps.every((regex) =>
        regex.test(form.watch("newPassword"))
      );
      return (
        <MDTypography
        component="span"
        pb={1}
        variant="caption"
        color={passedValidation ? "success" : "error"}
        fontWeight="regular"
      >
        <Icon
          fontSize="small"
          color={passedValidation ? "success" : "error"}
          sx={{
            marginLeft: 0,
            marginBottom: pxToRem(-5),
          }}
        >
          {passedValidation ? "check" : "clear"}
        </Icon>
        {text}
      </MDTypography>
      );
  };

  const goToLogin = useCallback(() => {
    navigate('/login')
  }, []);

  if (is2FaOpened) {
    return <TwoFactorAuth previewScreen={true} submitting={submitting} onCancel={close2Fa} onSubmitCode={loginWith2Fa}/>
  }

  return (
    <BasicLayout>
      {passwordChanged
      ? <PasswordChangedModal onClose={goToLogin} />
      : <Card style={{ width: 400, maxWidth: "90%", margin: "auto" }}>
        <MDBox
          dir="rtl"
          textAlign="center"
          bgColor="grey-100"
          borderRadius="xl"
          p={3}
          pb={6}
          mx={2}
          mt={-3}
        >
          <MDTypography
            paddingTop={1.5}
            fontWeight="bold"
            fontSize={pxToRem(22)}
            color="secondary"
          >
            {t("change_password:header")}
          </MDTypography>
        </MDBox>
        <MDBox
          pb={5}
          px={3}
          pt={6}
          width="100%"
          component="form"
          role="form"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          {requiredOldPassword && (
            <MDBox pb={1.5}>
              <MDFormField
                type="password"
                variant="outlined"
                fieldKey="oldPassword"
                label={t("resetPassword:oldPassword")}
                form={form}
                required={true}
              />
            </MDBox>
          )}
          <MDTypography color="error" textAlign="center" mb={2}>
            {t(form.formState.errors?.oldPassword?.message.toString() || '')}
          </MDTypography>
          <MDBox pb={1.5}>
            <MDFormField
              type="password"
              fieldKey="newPassword"
              customLabel={t("change_password:new_password")}
              form={form}
              required={true}
              showError={false}
              maxLength={50}
            />
          </MDBox>
          <MDFormField
            type="password"
            fieldKey="confirmPassword"
            customLabel={t("change_password:confirm_password")}
            form={form}
            required={true}
            showError={false}
            maxLength={50}
          />
          <MDBox display={"grid"}>
            <MDTypography
              pt={2}
              pb={1}
              variant="caption"
              sx={{ color: `${globalColors.primary1.main} !important` }}
            >
              {t("change_password:password_rules")}
            </MDTypography>
            <MDBox component="ul" ml={1.7} display={"grid"}>
                {renderValidationRow(
                  t("change_password:rule1"),
                  MIN_MAX_LENGTH_REGEX,
                  ENGLISH_LETTERS_NUMBERS_SPECIAL_REGEX
                )}
                {renderValidationRow(t("change_password:rule2"), ONE_LOWERCASE_REGEX)}
                {renderValidationRow(t("change_password:rule3"), ONE_UPPERCASE_REGEX)}
                {renderValidationRow(t("change_password:rule4"), ONE_SPECIAL_CHARACTER_REGEX)}
            </MDBox>
          </MDBox>
          <MDBox mt={5}>
            <MDButtonLoading
              loading={submitting}
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={!form.formState.isValid}
            >
              {t("change_password:save_btn")}
            </MDButtonLoading>
          </MDBox>
          
          <MDBox mt={2} display="flex" justifyContent="center">
            <MDTypography  mr={0.5}>
              {t('change_password:go_to')}
            </MDTypography>
            <Link to="/login">
              <MDTypography
                color="primary"
                fontWeight="bold"
              >
                {t("change_password:login_link")}
              </MDTypography>
            </Link>
          </MDBox>
        </MDBox>
      </Card>}
    </BasicLayout>
  );
};

export default ResetPassword;
