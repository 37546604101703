// Base styles
import borders from "assets/theme/base/borders";

// Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";

const { borderRadius } = borders;

// types
type Types = any;

const stepper: Types = {
  styleOverrides: {
    root: {
      padding: `${pxToRem(24)} 0 ${pxToRem(16)}`,
      borderRadius: borderRadius.lg,
      ".MuiStepIcon-text": { display: "none" },
    },
  },
};

export default stepper;
