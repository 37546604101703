import { Tab, Theme, styled } from "@mui/material";
import borders from "assets/theme/base/borders";
import typography from "assets/theme/base/typography";
import pxToRem from "assets/theme/functions/pxToRem";

const { size, fontWeightRegular } = typography;
const { borderRadius } = borders;

const TabToggleButton = styled(Tab)(({ theme }: { theme: Theme }) => {
  return {
    display: "flex !important",
    alignItems: "center !important",
    flex: "1 1 auto !important",
    maxWidth: "unset !important",
    minHeight: "unset !important",
    fontSize: size.xs,
    fontWeight: fontWeightRegular,
    lineHeight: "inherit !important",
    padding: pxToRem(16),
    borderRadius: borderRadius.lg,
    color: `${theme.palette.text.primary}`,
    opacity: "1 !important",
    width: 'auto!important',

    height: pxToRem(40),
    "&.Mui-selected": {
      backgroundColor: theme.palette.dark.main,
      color: theme.palette.light.main,
    },
    "& .material-icons, .material-icons-round": {
      marginBottom: "0 !important",
      marginRight: pxToRem(6),
    },
    "& button": {
      minWidth: '100%!important',
    },
    "& svg": {
      marginBottom: "0 !important",
      marginRight: pxToRem(6),
    },
  };
});

export default TabToggleButton;
