// Base styles
import * as buttonBaseStatic from "assets/components-static-styles/components/buttonBase";
// types
type Types = any;

const buttonBase: Types = {
  ...buttonBaseStatic.default,
  letterSpacing: '0.7px'
};

export default buttonBase;
