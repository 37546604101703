import { Auth } from "aws-amplify";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { ResetPageState, ResetPageStateSource } from "types/auth";
import { toastifySuccess, toastifyError } from "utils/toastify-message";
import { useAppDispatch } from "store/hooks";
import { checkPasswordReuse, saveOldPassword } from "store/slices/users-data";
// import { addChangePasswordLog } from "utils/server-log.utils";
import { setIsLoginAllowed } from "store/slices/auth";

export interface ResetFormData {
  oldPassword?: string;
  newPassword: string;
}

const useResetPassword = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state }: { state: ResetPageState | undefined } = useLocation();
  const [is2FaOpened, setIs2FaOpened] = useState(false);
  const userRef = useRef<any>();

  useEffect(() => {
    // redirect user to main page if no state (means that the "resetPassword" url was entered by user in the browser)
    if (!state?.source) {
      navigate("/");
    }
  }, []);

  const handleReusedOldPasswords = async (
    username: string,
    data: ResetFormData
  ): Promise<boolean> => {
    const isPasswordReused = await dispatch(
      checkPasswordReuse({ username, password: data.newPassword })
    ).unwrap();
    if (isPasswordReused) {
      toastifyError(t("resetPassword:cantUseOldPassword"));
      return true;
    }
    return false;
  };

  const handleForgetPasswordSource = async (data: ResetFormData) => {
    try {
      if (!(await handleReusedOldPasswords(state.username, data))) {
        await Auth.forgotPasswordSubmit(
          state?.username,
          state?.password,
          data.newPassword
        );
        toastifySuccess(t("resetPassword:successChangePassword"));
        // this will auto sign in the user
        const user = await Auth.signIn(state?.username, data.newPassword);
        return user;
      }
    } catch (error: any) {
      switch (error.message) {
        case "Invalid verification code provided, please try again.":
          toastifyError(t("auth:errors:INVALID_VERIFY_CODE"));
          navigate("/login");
          break;
        case "Invalid code provided, please request a code again.":
          toastifyError(t("auth:errors:EXPIRED_VERIFY_CODE"));
          navigate("/forgetPassword");
          break;

        default:
          throw error;
      }
    }
  };

  const handleRequiredNewPasswordSource = async (data: ResetFormData) => {
    const user = await Auth.signIn(state?.username, state?.password);
    await Auth.completeNewPassword(
      user, // the Cognito User Object
      data.newPassword
    );
    // toastifySuccess(t("resetPassword:successNewPassword"));
    return user;
  };

  const handleChangePasswordSource = async (data: ResetFormData) => {
    // note: user will keep logged if he was logged before
    const user = await Auth.currentAuthenticatedUser();
    if (user && !(await handleReusedOldPasswords(user.username, data))) {
      try {
        await Auth.changePassword(
          user, // the Cognito User Object
          data.oldPassword,
          data.newPassword
        );
        toastifySuccess(t("resetPassword:successChangePassword"));
        return user;
      } catch (error: any) {
        if (error?.message === "Incorrect username or password.") {
          toastifyError(t("resetPassword:failChangePassword"));
        }
      }
    }
  };

  const handleResetActionPerSource = (data: ResetFormData): Promise<any> => {
    switch (state?.source) {
      case ResetPageStateSource.FORGET_PASSWORD:
        return handleForgetPasswordSource(data);
      case ResetPageStateSource.REQUIRED_NEW_PASSWORD:
        return handleRequiredNewPasswordSource(data);
      case ResetPageStateSource.REQUIRED_CHANGE_PASSWORD:
      case ResetPageStateSource.CHANGE_PASSWORD_MANUALLY:
      default:
        return handleChangePasswordSource(data);
    }
  };

  const onSubmit = async (data: ResetFormData) => {
    try {
      dispatch(setIsLoginAllowed(false));

      setSubmitting(true);
      setPasswordChanged(false);
      const user = await handleResetActionPerSource(data);
      if (user) {
        // addChangePasswordLog(user.username, state?.source);
        await dispatch(
          saveOldPassword({
            username: user.username,
            password: data.newPassword,
          })
        );
        // navigate("/login");
      }
      userRef.current = user;
      setSubmitting(false);
      setIs2FaOpened(true);

    } catch (e: any) {
      switch (e.message) {
        case "Attempt limit exceeded, please try after some time.":
          toastifyError(t("resetPassword:attemptLimitExceeded"));
          break;
        default:
          toastifyError(t("resetPassword:failedMessage"));
          break;
      }
    } finally {
      setSubmitting(false);
    }
  };

  const loginWith2Fa = useCallback(async (code: string) => {
    try {
      setSubmitting(true);
      await Auth.confirmSignIn(userRef.current, code);
      setIs2FaOpened(false);
      setPasswordChanged(true);
      await Auth.signOut();
    } catch (err: any) {
      if (err.code === "CodeMismatchException") {
        toastifyError(t("login:invalid_sms_code"));
      }
      toastifyError(err.message);
      setSubmitting(false);
    }
  }, []);

  const close2Fa = useCallback(() => {
    setIs2FaOpened(false);
    setPasswordChanged(false);
  }, []);

  return { submitting, onSubmit, source: state?.source, passwordChanged, is2FaOpened, loginWith2Fa, close2Fa };
};

export default useResetPassword;
