import { useEffect, ReactNode } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

import MDBox from "components/MDBox";

//  context
import { useMaterialUIController, setLayout } from "context";

function DashboardLayout({ children }: { children: ReactNode }): React.ReactElement {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();
  const { darkMode } = controller;
  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  return (
    <MDBox
      zIndex={3}
      className={darkMode && "dark-theme"}
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",
        height: "100vh",
        overflow: "hidden",
        [breakpoints.down("lg")]: { py: 0 },
        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {children}
    </MDBox>
  );
}

export default DashboardLayout;
