const awsconfig = {
  Auth: {
    // identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL,
    userPoolWebClientId: process.env.REACT_APP_AWS_CLIENT_POOL,
  },
};

export default awsconfig;
