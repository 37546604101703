import MDInput from "components/MDInput";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useController } from "react-hook-form";
import { SelectItem } from "types/select";
import { Popper } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import { useTranslation } from "react-i18next";

const filter = createFilterOptions<SelectItem>();

export const AutocompleteForm = ({
  setValue,
  options,
  name,
  disabled,
  label,
  addFunc,
  addLabel,
  required,
  textFieldProps,
  onSelectInputChanged,
  inputValue,
  control,
  ...rest
}: any) => {
  const {
    field,
    formState,
    fieldState /* not in use but to prevent errors in console */,
    ...inputProps
  } = useController({
    name,
    control,
  });

  const { t } = useTranslation()
  const CustomPopper = function (props: any) {
    return <Popper {...props} style={{}} placement="bottom-end" />;
  };

  const handlePortKeyDown = (e: any) => {
    if (textFieldProps?.type == 'number' && ["e", "E", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Autocomplete    
      color="primary"
      {...inputProps}
      ListboxProps={{ style: { maxHeight: pxToRem(500) } }}
      key={name}
      disabled={disabled}
      PopperComponent={CustomPopper}
      options={options}
      getOptionLabel={getOptionLabel}
      noOptionsText={t("common:noResults")}
      isOptionEqualToValue={isOptionEqualToValue}
      name={field.name}
      value={
        (addFunc ? field.value : options.find(
          (option: string | SelectItem) =>
            option === field.value ||
            (option as SelectItem)?.value?.toString() ===
            field.value?.toString()
        )) ||
        null /* must have null as default value - otherwise reset form not working */
      }
      onChange={async (e, value: SelectItem) => {
        const newValue = getOptionValue(value) || null
        if(field.value == newValue){
          return
        }     
        if (
          addFunc &&
          getOptionValue(value) &&
          !options.find((x: SelectItem) => x.value == getOptionValue(value))
        ) {
          await addFunc(getOptionValue(value));
        }
        setValue(name, getOptionValue(value) || null, {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        });
        onSelectInputChanged && onSelectInputChanged(value);
        if (rest && rest.onChange) {
          rest.onChange(e, getOptionValue(value));
        }
       
      }}
     
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (addFunc && params.inputValue !== "") {
          if (!filtered.find(
            (x: SelectItem) => x.value == params.inputValue
          )) {
            filtered.push({
              value: params.inputValue,
              label: `${addLabel} "${params.inputValue}"`
            })
          }else{        
          }
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      {...rest}
      popupIcon={<KeyboardArrowDownIcon sx={{ fontSize: "20px !important" }} />}
      renderInput={(params) => {
        return (
          <MDInput
            ref={field.ref}
            fullWidth
            {...(textFieldProps || {})}
            placeholder={rest.placeholder}
            required={required}
            variant="standard"
            disabled={disabled}
            onKeyDown={handlePortKeyDown}
            error={!!(formState.errors ? formState.errors[name] : false)}
            label={label}
            {...params}
          />
        );
      }}
    />
  );
};

const isOptionEqualToValue = (option: SelectItem, value: string) => {
  return (
    (typeof option == "string" && option == value) ||
    (typeof option != "string" && option?.value?.toString() === value)
  );
};

const getOptionValue = (option: string | SelectItem) =>
  typeof option == "string" ? option : option?.value?.toString();

const getOptionLabel = (option: string | SelectItem) =>
  typeof option == "string" ? option : option?.label?.toString();
