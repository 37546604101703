import {
  CheckboxProps,
  FormControlLabel,
  Checkbox as MuiCheckbox,
} from "@mui/material";
import MDTypography from "components/MDTypography";

interface Props extends CheckboxProps {
  value: any;
  label?: string;
  labelProps?: any;
}

export const Checkbox = ({ value, label, labelProps = {}, ...rest }: Props) => {
  const generateCheckbox = () => {
    return (
      <MuiCheckbox style={{ margin: "0 -9px" }} checked={value} {...rest} />
    );
  };

  return label ? (
    <FormControlLabel
      sx={{
        width: "max-content",
        display: "flex",
        alignItems: "center",
        marginLeft: "0px",
      }}
      label={
        <MDTypography fontSize="12px" marginLeft="7px" {...labelProps}>
          {label}
        </MDTypography>
      }
      control={generateCheckbox()}
    />
  ) : (
    generateCheckbox()
  );
};
