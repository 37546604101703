import { ReactNode } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

//  examples components
import PageLayout from "components/LayoutContainers/PageLayout";

import logo from 'assets/images/logos/Logo.webp';
import { globalColors } from "assets/colors-global";

// Declaring props types for BasicLayout
interface Props {
  children: ReactNode;
}

function BasicLayout({ children }: Props) {
  return (
    <PageLayout
      background={"white"}
     >
      <MDBox position="fixed" top={0} left={0} zIndex={2} p={2}>
        <img src={logo} alt="" />
      </MDBox>
      <MDBox
        position="absolute"
        width="100vw"
        height="100%"
        minHeight="100vh"
      />
      <MDBox px={1} width="100%" minHeight="100vh" mx="auto" pt={6}>
        <MDBox
          display="flex"
          width="100%"
          justifyContent="flex-end"
          padding="56px 56px 0 0"
        ></MDBox>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="calc(100% - 150px)"
        >
          <Grid
            display={"grid"}
            justifyContent={"center"}
            item
            xs={11}
            sm={9}
            md={5}
            lg={4}
            xl={3}
          >
            {children}
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default BasicLayout;
