import { ResponseType } from "axios";

export enum Methods {
  GET = "get",
  POST = "post",
  PUT = "put",
  DEL = "delete",
}

export type ApiMapValueType = {
  url: string;
  method: Methods;
  noAuthHeader?: boolean;
  mock?: any;
  responseType?: ResponseType;
};

export type ApiMapType = {
  [key: string]: ApiMapValueType;
};

export type ApiParamsType = {
  key: ApiMapKeys;
  params?: any;
  data?: any;
  queryParams?: any;
  otherApiConfig?: ApiMapValueType;
  extraHeader?: any;
  mock?: any;
};

export type ApiSearchResult<T> = {
  data: T[];
  meta: { totalRowCount: number; page?: number; data?: any };
};

export type ApiSearchQueryParams<Data = any> = {
  page?: number;
  pageSize?: number;
  orderBy?: keyof DataEntity;
  orderDirection?: "asc" | "desc";
  search?: string;
  [key: string]: any;
};

export type ApiResponseType<T = any> = {
  data: T;
  status: number;
};

export default class ApiException extends Error {
  status!: boolean;
  message: string;
  data: { message: string; detail: string; statusCode: number };
  statusCode?: number;
}
