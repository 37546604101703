// @mui material components
import Card from "@mui/material/Card";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { globalColors } from "assets/colors-global";

import MDBox from "components/MDBox";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import { useTranslation } from "react-i18next";
import validationSchema from "./validation";
import MDFormField from "components/MDFormField";
import { useCallback, useState } from "react";
import MDTypography from "components/MDTypography";
import pxToRem from "assets/theme/functions/pxToRem";
import { useNavigate } from "react-router-dom";
import MDButtonLoading from "components/MDLoadingButton";
import { useAppDispatch } from "store/hooks";
import { toastifyError } from "utils/toastify-message";
import { ReactComponent as CloseIcon } from 'assets/Icons/XIcon.svg';
import { ReactComponent as ResetPasswordSuccessfullIcon } from 'assets/images/reset-password-success.svg';
import { Auth } from "aws-amplify";

const RequestSentModal = ({ onClose }: { onClose: () => void; }) => {
  const { t } = useTranslation();
  return (
    <MDBox zIndex={2} width={320} bgColor="white" p={2} pb={4} borderRadius="12px">
      <CloseIcon onClick={onClose} />
      <MDBox display="flex" justifyContent="center" mb={4} mt={2}>
        <ResetPasswordSuccessfullIcon />
      </MDBox>
      <MDBox>
        <MDTypography variant="h6" textAlign="center" mb={2}>
          {t('forgot_password:success_header')}
        </MDTypography>
        <MDTypography variant="body1" textAlign="center">
          {t('forgot_password:success_msg')}
        </MDTypography>
      </MDBox>
    </MDBox>
  )
}

const ForgetPassword = (): React.ReactElement => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [requestSent, setRequestSent] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const form = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  const goToLogin = useCallback(() => {
    navigate('/login');
  }, []);

  const onSubmit = async (data: any) => {
    try {
      setRequestSent(false);
      setSubmitting(true);
      await Auth.forgotPassword(data.email);
      setRequestSent(true);
    } catch (error: any) {
      toastifyError(t(error));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <BasicLayout>
      {requestSent 
      ? <RequestSentModal onClose={goToLogin} />
      : <Card style={{ width: 400, maxWidth: "90%", margin: "auto" }}>
        <MDBox
          dir="rtl"
          textAlign="center"
          bgColor="grey-100"
          borderRadius="xl"
          p={3}
          mx={2}
          mt={-3}
        >
        <MDTypography
          fontWeight="bold"
          fontSize={pxToRem(22)}
          color="secondary"
        >
          {t("forgot_password:header")}
        </MDTypography>
        <MDBox mt={1}>
          <MDTypography
            color="secondary"
          >
            {t("forgot_password:subheader")}
          </MDTypography>
        </MDBox>
        </MDBox>
        <MDBox
          pt={2.5}
          pb={5}
          px={3}
          width="100%"
          component="form"
          role="form"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <MDBox pb={1.5} pt={3}>
            <MDFormField
              fieldKey="email"
              customLabel={t("forgot_password:username")}
              placeholder={t("forgot_password:username_placeholder")}
              form={form}
              required={true}
              maxLength={50}
            />
          </MDBox>
          <MDBox mt={1}>
            <MDButtonLoading
              loading={submitting}
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={!form.formState.isValid}
            >
              {t("forgot_password:send")}
            </MDButtonLoading>
          </MDBox>
        </MDBox>
      </Card>}
    </BasicLayout>
  );
};

export default ForgetPassword;
