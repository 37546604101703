import { Card, Divider } from "@mui/material";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import PageContentContainer from "components/PageContainers/PageContentContainer";
import PageHeaderContainer from "components/PageContainers/PageHeaderContainer";
import ReadOnlyField from "components/ReadonlyField/ReadonlyField";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { useAppSelector } from "store/hooks";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { dayjs } from 'services/dayjs';
import { toastifySuccess } from "utils/toastify-message";

const HomePage = () => {
  const user = useAppSelector(state => state.users.loggedUser);
  const { t } = useTranslation();

  const [keyVisible, setKeyVisible] = useState(false);

  const copyKey = useCallback(async () => {
    await navigator.clipboard.writeText(user.encrypted_license);
    toastifySuccess('Key copied');
  }, [user.encrypted_license]);

  return (
    <DashboardLayout>
      <PageHeaderContainer>
        <DashboardNavbar pageTitle={t("home:title")} />
      </PageHeaderContainer>
      <PageContentContainer component="div">
        <MDBox p={0.5}>
          <Card>
            <MDBox  pt={2} px={2}>
              <MDTypography fontWeight="bold">Welcome {user.data.first_name} {user.data.last_name}!</MDTypography>
            </MDBox>

            <Divider />
            <MDBox p={2}>
              <MDBox display="flex">
                <ReadOnlyField label={"Email"} value={user.data.email} sx={{ width: '170px' }}></ReadOnlyField>
                <ReadOnlyField label={"Company"} value={user.data.company} sx={{ width: '170px' }}></ReadOnlyField>
                <ReadOnlyField label={"Phone number"} value={user.data.phone} sx={{ width: '170px' }}></ReadOnlyField>
              </MDBox>
              <MDBox display="flex">
                <ReadOnlyField label={"License Type"} value={user.license_type} sx={{ width: '170px' }}></ReadOnlyField>
                <ReadOnlyField label={"License Start Date"} value={dayjs.utc(user.license_start).format('DD/MM/YYYY')} sx={{ width: '170px' }}></ReadOnlyField>
                <ReadOnlyField label={"License End Date"} value={dayjs.utc(user.license_end).format('DD/MM/YYYY')} sx={{ width: '170px' }}></ReadOnlyField>
              </MDBox>
              <MDBox>
                <MDBox display="flex">
                  <ReadOnlyField label={"Activation Key"} elipsis={false} 
                 
                  value={
                    <MDBox display="flex" alignItems="center">
                      <MDBox onClick={() => setKeyVisible(prev => !prev)} mr={1} mt={0.5} sx={{ cursor: "pointer" }}>
                        {keyVisible ? <VisibilityIcon fontSize="medium" /> : <VisibilityOffIcon fontSize="medium" />}
                      </MDBox>
                      {keyVisible ? user.encrypted_license : `XXXX${'-XXXX'.repeat(20)}`}
                    </MDBox>}

                    ></ReadOnlyField>
                </MDBox>
                <MDBox display="flex" alignItems="center" sx={{ cursor: 'pointer' }} onClick={copyKey}>
                  <ContentCopyIcon />
                  <MDTypography fontWeight="bold" ml={1} textTransform="uppercase" fontSize="12px">Copy activation key</MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </MDBox>
      </PageContentContainer>
    </DashboardLayout>
  );
};

export default HomePage;
