import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { OrderFields } from "enums/order-fields.enum";
import http from "services/http/http";
import { addBaseListCases, BaseListState } from "store/base";
import { RootState } from "store/store";
import { Order } from "types/order";
import ApiException, { ApiSearchQueryParams, ApiSearchResult } from "types/api";

const BASE = "orders";

// Initial State

const initialState: BaseListState = {
  loading: "idle",
};

// Adapter

const ordersAdapter = createEntityAdapter<Order>({
  selectId: (order) => order[OrderFields.ID],
  sortComparer: (a, b) =>
    a[OrderFields.ORDERED_AT_DATE]
      .toString()
      .localeCompare(b[OrderFields.ORDERED_AT_DATE].toString()),
});

// Slice

const ordersSlice = createSlice({
  name: BASE,
  initialState: ordersAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    addBaseListCases<Order>(builder, fetchOrders, ordersAdapter);
  },
});

export const fetchOrders = createAsyncThunk(
  `${BASE}/fetchOrdersUrl`,
  async (
    {
      searchParams,
      filterObj = {},
    }: { searchParams?: ApiSearchQueryParams; filterObj?: any },
    { rejectWithValue }
  ) => {
    return await http
      .request<ApiSearchResult<Order>>({
        key: "getOrders",
        queryParams: filterObj,
      })
      .then((res) => res)
      .catch((err: ApiException) => rejectWithValue(err));
  }
);

// Selectors

export const ordersSelectors = ordersAdapter.getSelectors(
  (state: RootState) => state.orders
);

export default ordersSlice;
