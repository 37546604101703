// Base styles
import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";

// Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";
import rgba from "assets/theme/functions/rgba";

const { size, fontWeightRegular, fontWeightMedium } = typography;
const { white, primary } = colors;

// types
type Types = any;

const stepLabel: Types = {
  styleOverrides: {
    label: {
      marginTop: `${pxToRem(8)} !important`,
      fontWeight: fontWeightRegular,
      fontSize: size.xxs,
      color: rgba(primary.text, 0.5),
      textTransform: "uppercase",

      "&.Mui-active": {
        fontWeight: `${fontWeightMedium} !important`,
        color: `${rgba(primary.text, 1)} !important`,
      },

      "&.Mui-completed": {
        fontWeight: `${fontWeightMedium} !important`,
        color: `${rgba(primary.text, 1)} !important`,
      },
    },
  },
};

export default stepLabel;
