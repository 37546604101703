import {
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import http from "services/http/http";
import ApiException, { ApiSearchQueryParams } from "types/api";
import { PaginatedResponse } from "interfaces/api";
import { toastifySuccess } from "utils/toastify-message";
import { Lead } from "interfaces/lead";
  
  const BASE = "Leads";
  
  // Initial State
  interface LeadsState {
    loading: boolean;
    saleStatuses: string[];
  }
  
  const initialState: LeadsState = {
    loading: false,
    saleStatuses: [],
  };
  
  // Slice
  
  const leadsSlice = createSlice({
    name: BASE,
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder.addCase(fetchSaleStatuses.fulfilled, (state, action) => {
        state.saleStatuses = action.payload;
      });
    },
  });
  
  export const fetchLeads = createAsyncThunk(
    `${BASE}/fetchLeads`,
    async (
      {
        searchParams,
        filterObj,
      }: { searchParams?: ApiSearchQueryParams; filterObj?: any },
      { rejectWithValue }
    ) => {
      const { sign_up_date__gte, sign_up_date__lt, ...filter } = filterObj;

      const query = Object.entries(filter).reduce((acc, cur: any) => {
        return {
          ...acc,
          [cur[0]]: Array.isArray(cur[1]) ? cur[1].join() : cur[1]
        }
      }, {});
      return http
        .request<PaginatedResponse<Lead>>({
          key: "getLeads",
          queryParams: {
            ordering: searchParams.orderBy ? `${searchParams.orderDirection === 'asc' ? '':'-'}${searchParams.orderBy as string}` : '-sign_up_date',
            page: searchParams.page,
            page_size: searchParams.pageSize,
            ...query,
            search: searchParams.search,
            sign_up_date__gte,
            sign_up_date__lt
          },
        })
        .then((res) => {
          return res;
        })
        .catch((err: ApiException) => rejectWithValue(err));
    }
  );

  export const fetchSaleStatuses = createAsyncThunk(
    `${BASE}/fetchSaleStatuses`,
    async (
      _,
      { rejectWithValue }
    ) => {
      return http
        .request<string[]>({
          key: "getSaleStatuses",
        })
        .catch((err: ApiException) => rejectWithValue(err));
    }
  );

  export const leaveFeedback = createAsyncThunk(
    `${BASE}/leaveFeedback`,
    async ({id, text}: {id: string; text: string},
      { rejectWithValue }
    ) => {
      return http
        .request<any>({
          key: "leaveFeedback",
          data: { lead: id, message: text },
        })
        .catch((err: ApiException) => rejectWithValue(err));
    }
  );

  export default leadsSlice;
  