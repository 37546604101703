// @mui material components
import Card from "@mui/material/Card";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import MDBox from "components/MDBox";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import { useTranslation } from "react-i18next";
import validationSchema from "./validation";
import MDFormField from "components/MDFormField";
import MDTypography from "components/MDTypography";
import pxToRem from "assets/theme/functions/pxToRem";
import { Link } from "react-router-dom";
import MDButtonLoading from "components/MDLoadingButton";
import useLogin, { LoginFormData } from "./login.hook";
import TwoFactorAuth from "../components/TwoFactorAuth/TwoFactorAuth";

const Login = (): JSX.Element => {
  const { t } = useTranslation();
  const { onSubmit, submitting, error, is2FaOpened, loginWith2Fa, cancel2Fa } = useLogin();

  const form = useForm<LoginFormData>({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  if (is2FaOpened) {
    return (
      <TwoFactorAuth previewScreen={false} onSubmitCode={loginWith2Fa} submitting={submitting} onCancel={cancel2Fa} />
    )
  }

  return (
    <BasicLayout>
      <Card style={{ width: 400, maxWidth: "90%", margin: "auto" }}>
        <MDBox
          dir="rtl"
          textAlign="center"
          bgColor="grey-100"
          borderRadius="xl"
          p={3}
          mx={2}
          mt={-3}
        >
          <MDTypography
            fontWeight="bold"
            color="secondary"
            fontSize={pxToRem(22)}
          >
            {t("login:header")}
          </MDTypography>
          <MDBox mt={1}>
            <MDTypography
              color="secondary"
              fontSize={pxToRem(16)}
            >
              {t("login:subheader")}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox
          pt={2.5}
          pb={5}
          px={3}
          width="100%"
          component="form"
          role="form"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          {error ? <MDBox mt={2}>
            <MDTypography
              textAlign="center"
              color="error"
            >
              {t(error)}
            </MDTypography>
          </MDBox> : null}
          <MDBox pt={3}>              
            <MDFormField
              fieldKey="username"
              customLabel={t("login:username")}
              form={form}
              required={true}
              maxLength={50}
            />
          </MDBox>
          <MDBox>
            <MDFormField
              type="password"
              fieldKey="password"
              customLabel={t("login:password")}
              showError={false}
              form={form}
              required={true}
              maxLength={50}
            />
          </MDBox>
          {/* <MDBox mt={1} display="flex" justifyContent="flex-end">
            <Link to="/forgetPassword">
              <MDTypography
                color="primary"
                sx={{
                  fontWeight: 600
                }}
              >
                {t("login:forgot_password")}
              </MDTypography>
            </Link>
          </MDBox> */}
          <MDBox mt={5}>
            <MDButtonLoading
              loading={submitting}
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={!form.formState.isValid}
            >
              {t("login:login_btn")}
            </MDButtonLoading>
          </MDBox>
{/* 
          <MDBox mt={2} display="flex" justifyContent="center">
            <MDTypography  mr={0.5}>
              {t('login:dont_have_account')}
            </MDTypography>
            <Link to="/login">
              <MDTypography
                color="primary"
                sx={{
                  fontWeight: 600
                }}
              >
                {t("login:signup_link")}
              </MDTypography>
            </Link>
          </MDBox> */}
        </MDBox>
      </Card>
    </BasicLayout>
  );
};

export default Login;
