// Base styles
import * as inputOutlinedStatic from "assets/components-static-styles/components/form/inputOutlined";
import colors from "assets/theme/base/colors";

// Helper functions
import { merge } from "lodash";

const { inputFormBorderColor, transparent, text, primary } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    root: {
      backgroundColor: transparent.main,

      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: inputFormBorderColor,
      },
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: inputFormBorderColor,
        },
      },
    },

    notchedOutline: {
      borderColor: primary.text,
    },

    input: {
      color: text.main,
      backgroundColor: transparent.main,
    },

    multiline: {
      color: text.main,
    },
  },
};

const inputOutlined: Types = merge(themeStyles, inputOutlinedStatic.default);

export default inputOutlined;
