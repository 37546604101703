import { configureStore } from "@reduxjs/toolkit";
import configSlice from "./slices/config";
import authSlice from "./slices/auth";
import userSlice from "./slices/user";
import generalSlice from "./slices/general";
import usersSlice from "./slices/users-data";
import ordersSlice from "./slices/orders";
import campaignsSlice from "./slices/campaigns";
import leadsSlice from "./slices/leads";
import notificationsSlice from "./slices/notifications";
import dashboardSlice from "./slices/dashboard";

const store = configureStore({
  reducer: {
    general: generalSlice.reducer,
    auth: authSlice.reducer,
    user: userSlice.reducer,
    config: configSlice.reducer,
    users: usersSlice.reducer,
    orders: ordersSlice.reducer,
    campaigns: campaignsSlice.reducer,
    leads: leadsSlice.reducer,
    notifications: notificationsSlice.reducer,
    dashboard: dashboardSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
