import { ButtonProps, CircularProgress } from "@mui/material";
import MDButton from "components/MDButton";
import { FC, ReactNode } from "react";

interface Props extends Omit<ButtonProps, "color" | "variant"> {
  color?:
    | "white"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "contrast"
    | "light"
    | "dark"
    | "default"
    | "greenButton"
    | "black"
  variant?: "text" | "contained" | "outlined" | "gradient";
  size?: "small" | "medium" | "large";
  circular?: boolean;
  iconOnly?: boolean;
  children?: ReactNode;
  loading?: boolean;
}

const MDButtonLoading: FC<Props> = ({ loading = false, disabled, children, ...rest }) => {
  return (
    <MDButton
      disabled={disabled || loading}
      startIcon={loading && <CircularProgress size="2vh" color="info" />}
      {...rest}
    >{children}</MDButton>
  );
};

export default MDButtonLoading