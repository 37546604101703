import { object, ref, string } from "yup";

export const ONE_LOWERCASE_REGEX = /(?=.*[a-z])/;
export const ONE_UPPERCASE_REGEX = /(?=.*[A-Z])/;
export const ONE_SPECIAL_CHARACTER_REGEX =
  /(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+-])/;
export const MIN_MAX_LENGTH_REGEX = /^.{10,20}$/;

const validationSchema = object().shape({
  email: string()
    .required(" ")
    .email(" "),
});

export default validationSchema;
